import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getShopCategores } from '../../../actions/PageActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'
import { Button, TextField } from '@mui/material';

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../components/Table';
import { logEvent } from '@firebase/analytics';


const Rows = [
  {
      title:'ID',
      key:'ID',
      width:'50px'
  },
  {
      title:'Название',
      key:'TITLE',
  }
]

class SHOP_CATEGORIES extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }

    componentDidMount(){
      logEvent(global.analytics, 'open_page', {
        data: 'SHOP_CATEGORIES' 
      })
    }

    Save(ID){
      let data = {
        operation:'createUpdateSome',
        Table:'SHOP_CATEGORIES',
        data:{},
      }
      Object.keys(this.input.dataWindow).forEach((key,index)=>{
        data.data[key] = this.input.dataWindow[key].value
      })
      if(ID!=null)
        data.ID = ID

      
      GlobalFunctions.API_task(data).then(res=>{
        logEvent(global.analytics, 'create_category', {
          data:res,
        })
        this.props.closePopUp()
          this.props.getShopCategores()
      })  
    }
    
    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'SHOP_CATEGORIES',
          ID
        }

        
        
        GlobalFunctions.API_task(data).then(res=>{
          this.props.closePopUp()
          this.props.getShopCategores()
          setTimeout(()=>resolve(),1)
          logEvent(global.analytics, 'delete_category', {
            data:ID,
          })
        }).catch(()=>reject()) 
      })
      
    }

  

    render (){
      const {filter,Data} = this.state
        return (
        <MainView>
          <div style={{display:'inline-table'}}>
          <TextField
            className='me-2'
            label='Поиск'
            variant="filled"
            size="small"
            onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
          />

          <Button className='me-2 align-middle' variant='outlined' size='small'onClick={()=>{
            Object.keys(this.checkBoxs).forEach((key,index)=>{
              this.checkBoxs[key].checked = true
            })
          }}>Выбрать все</Button>

          <Button className='me-2 align-middle' variant='outlined' size='small'onClick={()=>{
            Object.keys(this.checkBoxs).forEach((key,index)=>{
              this.checkBoxs[key].checked = false
            })
          }}>Cнять выделение</Button>

          <Button className='me-2 align-middle' variant='outlined' size='small'onClick={()=>{
            this.props.openPopUp(<DataWindow Context={this}/>)
          }}>Удалить выбранные</Button> 

          <Button className='me-2 align-middle' variant='outlined' size='small'onClick={()=>{
           this.props.openPopUp(<DataWindow Context={this}/>)
          }}>Добавить</Button>
                


           
          </div>
          
          <br/>
          <Table
                className='mt-3'
                rows={Rows}
                canCheck={true}
                checkBoxs={this.checkBoxs}
                data={this.props.SHOP_CATEGORIES.filter(a=> filter==null || a.TITLE.toUpperCase().indexOf(filter)!=-1 )}
                itemOnClick={({data,index})=>{
                    this.props.openPopUp(<DataWindow ID_OBJECT={data.ID} Context={this}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{data.ID}</td>,
                    <td>{data.TITLE}</td>,
                ]}/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`

`

const Btn = styled.div`

`

const DataWindow = (props)=>{
  const POP_DataWindow = styled.div`
  width:250px;
  height: auto;
  `
  const form_fields=[
    {
      title:'Название',
      placeholder:'',
      name:'TITLE'
    },
  ]

  setTimeout(()=>{
      try{
          if(props.ID_OBJECT != null ){
           
              Object.keys(props.Context.input.dataWindow).forEach(key =>{
                
                  let Rep_elem= props.Context.props.SHOP_CATEGORIES.filter(data => data.ID == props.ID_OBJECT)[0]
                  props.Context.input.dataWindow[key].value = Rep_elem[key]
                  
              })
          }
      }catch(e){}
  },1)
  

  return (
      <POP_DataWindow> 
          <h5>Категория</h5>
          {form_fields.map(input => 
              <Input    
              {...input}
              Ref={props.Context.input.dataWindow}/>
          )} 

                <Button className='me-1' variant='outlined' size='small'onClick={()=>props.Context.Save(props.ID_OBJECT)}>Сохранить</Button>
                {props.ID_OBJECT==null?null:
                    <Button className='me-1 float-end' variant='text' color='error' size='small'onClick={()=>props.Context.Delete(props.ID_OBJECT)}>Удалить</Button>
                }
          
      </POP_DataWindow>
  )
}



const Search = styled.input`
width:200px;
`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getShopCategores:(arg)=>dispatch(getShopCategores(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SHOP_CATEGORIES:store.page.SHOP_CATEGORIES
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(SHOP_CATEGORIES)

