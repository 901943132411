import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { getAchivs } from '../../../actions/PageActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { Draggable, Droppable } from 'react-drag-and-drop'
import trashCan from '../../../design/trashCan.svg'
import Button from '@mui/material/Button';
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import POP_acive from './POP_acive';
import { logEvent } from '@firebase/analytics';
import { TextField } from '@mui/material';


class Bonus extends React.Component {
  constructor(props) {
      super()
      this.state = {
       TYPE:'A',
       Filter:null,
       TrashBox:false
      } 
  }

  componentDidMount(){
    logEvent(global.analytics, 'open_page', {
      data: 'Bonus' 
    })
  }

  addAchiveToLVL(ID_ACHIVE,LVL){
    GlobalFunctions.API_task({
      operation:'addAchiveToLVL',
      data:{
        ID_ACHIVE,
        LVL
      }
      
    }).then(res=>{
      logEvent(global.analytics, 'addAchiveToLVL', {
        data:{
          ID_ACHIVE,
          LVL
        }
      })
      this.props.getAchivs()
    })
  }

  

  deleteAciveFromLVL(ID){
    GlobalFunctions.API_task({
      operation:'deleteAchiveFromLVL',
      ID
      
    }).then(res=>{
      logEvent(global.analytics, 'deleteAciveFromLVL')
      this.props.getAchivs()
    })
  }

  replaceAciveFromLVL(ID,toLVL){
    GlobalFunctions.API_task({
      operation:'replaceAciveFromLVL',
      ID,
      toLVL
    }).then(res=>{
      logEvent(global.analytics, 'replaceAciveFromLVL',{
        data:{
          ID,
          toLVL
        }
      })
      this.props.getAchivs()
    })
  }

  renderLVLs(){
    const {VARS,ACHIVE_LVL_LOGIC} = this.props
    
    let result =[]
    for(let i =1;i<=VARS.COUNT_LVLS*1;i++){
      let count = 0
      result.push(
        <Droppable
                types={['achiv','curachiv']} // <= allowed drop types
                onDrop={(e)=>{
                  
                  if(e.achiv!='')
                    this.addAchiveToLVL(e.achiv*1,i)
                  else{
                    this.replaceAciveFromLVL(e.curachiv,i)
                  }
                }}>
            
          <div>
            <LVL>{i}</LVL>
          
            
              <Achivs>
                {ACHIVE_LVL_LOGIC.map(ch_lvl=>{
                  if(ch_lvl.LVL!=i || ch_lvl.ACHIVE==null){
                    return null
                  }else{
                    count++
                    return(
                      <Draggable type="curachiv" data={ch_lvl.ID} >
                        <p><img src={`${global.URL_host}img/${ch_lvl.ACHIVE.IMAGES_URL[0]}`}/> {ch_lvl.ACHIVE.TITLE}</p>
                      </Draggable>
                    )
                  }
                })}
              </Achivs>
            
            <LVL_line count={count}/>
          </div>
        </Droppable>
      )
    }
    return result
  }

  render (){
    const {VARS,ACHIVEMENTS} = this.props
    const {TYPE,Filter,TrashBox} = this.state

    return (
      <MainView>
        <TopView>
          <TextField
            className='me-2'
            label='Поиск'
            variant="filled"
            size='small'
            onKeyUp={(e)=>this.setState({Filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
          />

          <Button className='ms-2 align-middle mt-1' variant='text'  onClick={()=>{
             this.props.openPopUp(<POP_acive/>)
            }}>Добавить</Button>
         
          
        </TopView>
       
        
        <Row className='row'>
          
          <Col className='col-7 pt-5'>
          <Scroll>
           <div className='row row-cols-lg-2 mt-5'>
                   
            {(Filter==null?ACHIVEMENTS:ACHIVEMENTS.filter(ach=>ach.TITLE.toUpperCase().indexOf(Filter)>=0)).map(a=>
              
              <div className='col'>
                <Draggable type="achiv" data={a.ID}>
                  <Achiv onClick={()=>this.props.openPopUp(<POP_acive ID_OBJECT={a.ID} />)}>
                      <img src={`${global.URL_host}img/${a.IMAGES_URL[0]}`} />
                      <h3>{a.TITLE}</h3>
                      <p>{a.DESCR_SM}</p>
                      <div/>
                    </Achiv>
                  </Draggable>
                </div>
                
                )}   
                
           </div>
           </Scroll>
          </Col>
          <Col className='col-5' onMouseEnter={()=>this.setState({TrashBox:true})} onMouseLeave={()=>this.setState({TrashBox:false})}>
            <Cont className='pt-5'>
              <Droppable
                  types={['curachiv']} 
                  onDrop={(e)=>this.deleteAciveFromLVL(e.curachiv)}>
                <Trash isVisible={TrashBox} src={trashCan}/>
              </Droppable>
              {this.renderLVLs()}
            </Cont>
          </Col>
        </Row>

      </MainView>
      )
  } 
}
const Trash = styled.img`
display: block;
position: fixed;
z-index: 1;
top: 70px;
right: 30px;
width: 40px;
height: 40px;
padding: 10px;
background: rgb(246, 246, 246);
border-radius: 50px;
transition: .5s;
opacity:${props=>props.isVisible?1:0}
`


const TopView = styled.div`
position:relative;
z-index:1;
`

const Achivs = styled.div`
position: absolute;
margin-left: 50px;
margin-top: 25px;
width:200px;
& img{
  width: 20px;
height: 20px;
object-fit: cover;
border-radius: 50px;
margin-left: -5px;
margin-top: -2px;
margin-right: 5px;
}
& p{

  border: 1px solid rgb(235, 235, 235);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
  margin: 0px;
  margin-bottom: 10px;
  cursor: pointer;
  height: 30px;
  overflow:hidden;
  background:white;
}
`

const LVL = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: rgb(166, 144, 127);
  text-align: center;
  font-weight: 900;
  color: white;
  line-height: 39px;
  position:relative;
  cursor:pointer;
  transition:.2s;
  font-size: 22px;
`
const LVL_line = styled.div`
width: 5px;
transition:1s;
height:${props=>props.count*40+25}px;
margin-left: 18px;
margin-top: 10px;
border-radius: 50px;
background: rgb(166,144,127);
margin-bottom: 10px;
  
`

const Search = styled.input`
width:300px;
`

const Achiv = styled.div`
width:100%;
padding:10px;
background:white;
box-shadow:10px 10px 30px rgba(0,0,0,0.05);
border-radius:10px;
cursor:pointer;
position:relative;
overflow:hidden;
transition:.3s;
height: 110px;
margin-bottom:20px;
&:hover{
  transform:scale(1.02);
}
& h3{
  font-size:14px;
  line-height:16px;
  margin-bottom:2px;
  margin-left:100px;
  height: 15px;
overflow: hidden;
}
& p{
  font-size: 11px;
line-height: 11px;
margin: 0;
margin-left: 100px;
height: 46px;
overflow: hidden;
  
}
& img{
  border-radius:10px;
  display:block;
  height:90px;
  width:90px;
  background:gray;
  position:absolute;
  left:10px;
  top:10px;
  object-fit:cover;
}
& div{
  border-radius:10px;
  position:absolute;
  left:0px;
  top:0px;
  z-index:1;
  background:rgba(0,0,0,0);
  width:100%;
  height:100%;
}
`

const Row = styled.div`
margin-top: -68px;
position: absolute;
width: calc(100% + 43px);
height: calc(100vh - 52px);
padding: 0 !important;
}
`

const Cont = styled.div`
padding:20px !important;
margin:0 !important;
position:relative;
height: calc(100vh - 51px);
background:white;
overflow-x:hidden;
overflow-y:auto;
`
const Scroll = styled.div`
width:100%;
padding:12px !important;
padding-top: 44px !important;
margin:0 !important;
position:absolute;
left:0px;
top:0px;
height: calc(100vh - 51px);

overflow-x:visible;
overflow-y:auto;
`

const MainView = styled.div`
position:relative;
`

const Col = styled.div`
position:relative;
`

const Btn = styled.div`

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getAchivs:(arg)=>dispatch(getAchivs(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      VARS:store.page.VARS,
      ACHIVEMENTS:store.page.ACHIVEMENTS,
      ACHIVE_LVL_LOGIC:store.page.ACHIVE_LVL_LOGIC,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Bonus)

