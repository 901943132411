import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getNews } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import { withToast } from '../../components/Toasts';
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';

const form_fields = {
    main : [
        {
            title:'Заголовок',
            placeholder:'50 символов',
            name:'TITLE'
        },
        {
            title:'Описание короткое',
            placeholder:'80 символов',
            name:'DESCR_SM',
            type:'textarea'
        },
        {
            title:'Описание полное',
            placeholder:'300 символов',
            type:'textarea',
            name:'DESCR_LG'
        }
    ],
    akcia : [
        {
            title:'Начало акции',
            placeholder:'',
            type:'datetime-local',
            name:'DATE_TIME_START',
            min:'2021-01-01T00:00',
        },
        {
            title:'Окончание акции',
            placeholder:'',
            type:'datetime-local',
            name:'DATE_TIME_END',
            min:'2021-01-01T00:00',
        }
    ]
}




class POP_news extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOtmena:false,
            filter:null,
            ID_OBJECT:props.ID_OBJECT,
            Representors:[],
            nowLoading:false,
            Self:{
                TYPE:'N'
            },
            
        }
        this.input={
            main:{},
            persons:{},
        }

        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }

    componentDidMount(){
       
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    getSelf(){
        
        let Self = this.props.NEWS.find(data => data.ID == this.state.ID_OBJECT)
        
        this.setState({Self})
        logEvent(global.analytics, 'open_object', {
            data: 'POP_news',
            object:Self
          })
        setTimeout(()=>{
           
            Object.keys(this.input.main).forEach(key =>{
                if(this.input.main[key].type=='date' || this.input.main[key].type =='datetime-local'){
                    let val = GlobalFunctions.isStringDateTime(Self[key]) ? 
                        Self[key].replace(' ','T'):
                        Self[key]

                    this.input.main[key].value = val
                }else
                    this.input.main[key].value = Self[key]
                
            })
            this.setState({isOtmena:Self.ID_STATUS==3})
            setTimeout(()=>{
                try{
                    this.input.main.WHY_NOT.value = Self.WHY_NOT
                    }catch(e){}
            },10)
            
        },1)
    }

    Delete(){
        GlobalFunctions.API_task({
            operation:'deleteElement',
            Table:'NEWS',
            ID:this.props.ID_OBJECT
        }).then(res=>{
            logEvent(global.analytics, 'delete_news', {
                data:this.props.ID_OBJECT,
            })
            this.props.getNews()
            this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
            this.props.closePopUp()
        })
    }

    Save(){
        if(this.state.nowLoading) return

        if(!GlobalFunctions.checkReqvireds(this.input.main,['DATE_TIME_START','DATE_TIME_END'])) return
        if(this.filesToUpload.length==0 && this.props.ID_OBJECT==null) {
            this.props.openPopUp(<Alert text="Нужно выбрать хотябы одно изображение для загрузки"/>)
            return
        }
        let data = {}
        Object.keys(this.input.main).forEach(key=>{
            try{
                let val = this.input.main[key].value
                data[key] = val.length == 0 ? null : val
            }catch(e){}
        })

        if(this.input.main.TYPE == 'N'){
            data['DATE_TIME_START'] = null
            data['DATE_TIME_END'] = null
        }else{
            if(data['DATE_TIME_START']=='') data['DATE_TIME_START']=null
            if(data['DATE_TIME_END']=='')   data['DATE_TIME_END']=null
        }
        let reqv = {
            operation:'createUpdateNews',
            data
        }
        if(this.props.ID_OBJECT != null)
            reqv.ID = this.props.ID_OBJECT

        if(data.WHY_NOT==null || data.WHY_NOT=='' || data.WHY_NOT==' ')
            data.WHY_NOT = null

        this.setState({nowLoading:true})
        GlobalFunctions.API_task(reqv).then(res=>{
            let promises = []
            this.props.getNews()

            
            
            if(this.filesToUpload.length>0)
                promises.push(GlobalFunctions.API_files({
                    operation:'setIMAGE',
                    Table:'NEWS',
                    ID:res.ID
                },this.filesToUpload))

            if(this.filesToDelete.length>0)
                promises.push(GlobalFunctions.API_task({
                    operation:'deleteIMAGE',
                    ID:this.props.ID_OBJECT,
                    Table:'NEWS',
                    filesToDelete:JSON.stringify(this.filesToDelete)
                }))

            if(promises.length>0){
                this.setState({nowLoading:true})
            }else{
                this.setState({nowLoading:false})
            }

            Promise.all(promises).then(()=>{
                this.setState({nowLoading:false})
                this.props.getNews()
            })
            this.props.closePopUp()
        }).catch(e=>{
            this.setState({nowLoading:false})
        })
        .finally(()=>{
            
        })
    }

    render (){
        const {filter, Representors,Self,isOtmena} = this.state
        const {Arendators, SHOP_CATEGORIES, NEWS_STATUSES,ID_OBJECT} = this.props
        
        if(Self.ID==null && ID_OBJECT!=null) return <div/>
        return (
            <POP> 
                <h4>Новости и акции</h4>
                <div className='row'>
                    <div className='col-6'>
                        <ComboBox
                            onChange={(e)=>
                            this.setState({Self:{
                                ...Self,
                                TYPE:e.target.value
                            }})
                            }
                            name='TYPE'
                            title='Тип'
                            Ref={this.input.main}>
                            <option value='N' selected>Новость</option>
                            <option value='A'>Акция</option>
                        </ComboBox>
                    </div>
                    <div className='col-6'>
                        <ComboBox
                            name='ID_STATUS'
                            title='Статус'
                            onChange={(e)=>{
                                this.setState({isOtmena:e.target.value==3})
                                try{
                                    this.input.main.WHY_NOT.value = Self.WHY_NOT
                                }catch(e){}
                            }}
                            Ref={this.input.main}>
                            {NEWS_STATUSES.map(s=>
                                s.ID==5?null:
                                <option value={s.ID} selected={s.ID==1}>{s.TITLE}</option>
                            )}
                        </ComboBox>
                    </div>
                </div>
                

                
                
               {form_fields.main.map(input => 
                    <Input    
                    {...input}
                    Ref={this.input.main}/>
                )} 

                {Self.TYPE!='A'? null:
                    <div className='row'>
                        {form_fields.akcia.map(input => 
                            <div className='col-6'>
                                <Input    
                                {...input}
                                Ref={this.input.main}/>
                            </div>
                        )}
                    </div>
                }

                {!isOtmena?null:
                 <Input    
                 title='Причина отмены'
                 type='textarea'
                 placeholder='Напишите арендатору причину, по которой предложенная им новость отменина'
                 name='WHY_NOT'
                 Ref={this.input.main}/>
                }

                <div className='mb-2' style={{height:140}}>
                <Input_image multiple={true} value={Self.IMAGES_URL} onChange={(filesToUpload,filesToDelete)=>{
                    
                    this.filesToUpload=filesToUpload
                    this.filesToDelete=filesToDelete
                    
                }}/>
                </div>

                
                <Button className='me-1' variant='outlined' size='small'onClick={()=>{
                    this.Save()
                }}>{this.state.nowLoading?'Загрузка...':'Сохранить'}</Button>
                

                {ID_OBJECT!=null?
                    <Button className='me-1 float-end' variant='text' color='error' size='small'onClick={()=>{
                        this.Delete()
                    }}>Удалить</Button>
                :null}

                
            </POP>
        )
    }
}

const POP = styled.div`
width:650px;
height:auto;
`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),

        getNews: (arg) => dispatch(getNews(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        NEWS_STATUSES : store.page.NEWS_STATUSES,
        NEWS: store.page.NEWS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(POP_news));

