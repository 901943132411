
  const initialState = {
    Arendators:[],
    SHOP_CATEGORIES:[],
    AREND_STATUSES:[],
    NEWS_STATUSES:[],
    NEWS:[],
    VARS:[],//статические переменные
    ACHIVEMENTS:[],
    ACHIVE_LVL_LOGIC:[],
    SCANED:[],
    SCANED_STATUSES:[],
    USERS:[],
    MAP_LAYERS:[],
    MAP_MARKS:[],
    SLIDERS:[],
    TEXTS:[],
    ENTERTAIMENTS:[],
    ARENDATORS_STARS:[],
  };
  
  export function pageReducer(state = initialState, action) {
    switch (action.type) {
      case "GET_TEXTS":
        let TEXTS = {}
        action.payload.map(txt=>{
          TEXTS[txt.NAME]={}
          TEXTS[txt.NAME]=txt
        })
        return {
          ...state,
          TEXTS
        };
        
      case 'getArendators':
        return{
          ...state,
          Arendators:action.payload
        }

      case 'SET_ACHIVES':
        return{
          ...state,
          ACHIVE_LVL_LOGIC:action.payload.ACHIVE_LVL_LOGIC,
          ACHIVEMENTS:action.payload.ACHIVEMENTS,
        }

      case 'getMapData':
        return{
          ...state,
          MAP_LAYERS:action.payload.MAP_LAYERS,
          MAP_MARKS:action.payload.MAP_MARKS,
        }

      case 'JUST_SET_VALUE':
        let temp_state={}
        Object.assign(temp_state,state)
        temp_state[action.key] = action.payload
        
        return temp_state
      
      
      default:
        return state;
    }
  }