import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { getMapData } from '../../../actions/PageActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import ScrollContainer from 'react-indiana-drag-scroll'
import { withRouter } from "react-router"
import MarksView from './Marks'
import {MapSVG} from '../../../assets/map'
import { logEvent } from '@firebase/analytics';

const mapWidth = 1920
const mapHeight = 1080


class Map extends React.Component {
  constructor(props) {
      super()
      this.state = {
       Filter:null,
       Resize:80,
       Flor:1,
       //Marks:[],
        selectedMark:null
      } 
      this.scrollContainer=null
  }

  componentDidMount(){

    logEvent(global.analytics, 'open_page', {
        data: 'Map' 
      })
    
    let {Resize} = this.state
    let elem = this.scrollContainer.getElement()

    let offsetWidth = elem.offsetWidth
    let offsetHeight = elem.offsetHeight

    let window_HorizonatlCenter = offsetWidth/2
    let window_VerticalCenter = offsetHeight/2

    let map_HorizonatlCenter = (mapWidth*(Resize/100+0.5)+65)/2
    let map_VerticalCenter = (mapHeight*(Resize/100+0.5)+65)/2
    
    
    let horizontal_scroll = map_HorizonatlCenter - window_HorizonatlCenter
    let vertical_scroll = map_VerticalCenter - window_VerticalCenter

    elem.scrollLeft=horizontal_scroll
    elem.scrollTop=vertical_scroll
    this.props.getMapData()
}

createMark(X,Y){
    const {Resize,selectedMark,Flor} = this.state
    GlobalFunctions.API_task({
        operation:'createMark',
        data:{
            FLOR:this.state.Flor,
            X:Math.floor(X)+273,
            Y:Math.floor(Y )+66
        }
    }).then(res => {
        logEvent(global.analytics, 'create_map_mark', {
            data: res
        })
        this.props.getMapData()
        
        this.props.openPopUp(
            <MarksView
            ID_OBJECT={res.ID}
            callBack={()=>this.props.getMapData()}/>
        )
    })
}


  render (){
    const {Resize,selectedMark,Flor} = this.state
    const {NEWS,MAP_LAYERS,MAP_MARKS,data} = this.props

    let Marks =[]

    MAP_MARKS.forEach(mark=>{
            if(mark.FLOR == Flor){
                Marks.push(mark)
            }

    })
    
    

    return (
      <MainView>
        <div className='row'>
            <div className='col-12 p-0'>
                <Flors>
                    {[0,1,2,3,4,5].map(flor=>
                        <p className={flor==Flor?'selected':''} onClick={()=>this.setState({Flor:flor})}>{flor}</p>
                    )}
                </Flors>
                <ScrollContainer
                ref={ref=>this.scrollContainer=ref}
                className="scroll-container"
                style={{
                    
                    height:"calc(100vh - 50px)"
                }}>
                    <MapBg 
                    ref={ref=>this.MapBg=ref}
                    onClick={(e)=>{
                        const {scrollTop,scrollLeft} = this.scrollContainer.getElement()
                       
                        let x = e.pageX + scrollLeft - this.MapBg.offsetLeft,
                        y = e.pageY + scrollTop - this.MapBg.offsetTop
                        x = x /(Resize / 100 + 0.5) - 450
                        y = y /(Resize / 100 + 0.5) - 105
                        
                        this.createMark(x,y)

                        
                    }} size={Resize} >
                        <img id='BG' src={MapSVG[Flor]}/>
                        {Marks.map(mark=>(
                            <MapMark
                            onMouseEnter={()=>{
                                this.setState({selectedMark:mark.ID})
                            }}
                            src={
                                mark.IMAGES_URL!=null && mark.IMAGES_URL[0]!=null?
                                    `${global.URL_host}img/${mark.IMAGES_URL[0]}`
                                :mark.AREND_URL_LOGO!=null && mark.AREND_URL_LOGO[0]!=null?
                                   `${global.URL_host}img/${mark.AREND_URL_LOGO[0]}`
                                :null
                            }
                            layer ={mark.LAYER}
                            className={selectedMark == mark.ID? "selected":""}
                            key={`mark_${mark.ID}`}
                            x={mark.X*(Resize / 100 + 0.5)}
                            y={mark.Y*(Resize / 100 + 0.5)}
                            onClick={(e)=>{
                                e.stopPropagation()
                                
                                this.props.openPopUp(
                                    <MarksView
                                    ID_OBJECT={mark.ID}
                                    callBack={()=>this.props.getMapData()}/>
                                )
                            }}/>
                                
                            
                        ))}
                        
                    </MapBg>
                </ScrollContainer>
            </div>
        </div>


      </MainView>
      )
  } 
}

const MainView = styled.div`
margin:-20px;
position:relative;
`


const MapBg = styled.div`
width:${props=>mapWidth*(props.size/100+0.5)}px;
height:${props=>mapHeight*(props.size/100+0.5)}px;
margin:100px;
opacity:0.9;
border-radius:10px;
position:relative;
& img#BG{
    width:100%;
    height:100%
}
`

const Flors = styled.div`
position: absolute;
top: 20px;
left: 20px;
z-index: 10;
background: rgba(255,255,255,0.7);
backdrop-filter:blur(7px);
border-radius: 10px;
box-shadow: 10px 10px 30px rgba(0,0,0,0.03);
overflow:hidden;
font-weight:300;
& p{
    cursor:pointer;
    color:rgb(100,100,100);
    transition:.3s;
    width:50px;
    height:50px;
    line-height: 50px;
    margin:0px;
    text-align: center;
}
& .selected{
    background: white;
    color:black;
    font-weight:700;
}
& p:hover{
    background: white;
    color:black;
}
`

const MapMarkTitle = styled.p`
    position:absolute;
    top:20px;
    transform:scale(0.5);
    color:white;
    line-height: 7px;
    font-size: 7px !important;
    font-weight:300 !important;
    text-align:center;
    width:100px;
    left:calc(50% - 50px);
    transition:.4s;
    opacity:0;
    z-index:-1;
`

const MapMark = styled.img`
    width:25px;
    height:25px;
    display:block;
    object-fit:cover;
    border-radius:10px;
    position:absolute;
    top:${props=>props.y - 12.5}px;
    left:${props=>props.x - 12.5}px;
    border:2px solid ${props=>props.layer.COLOR};
    background:white;
    transition:transform .2s ease-in-out;
    text-align:center;
    font-weight:800;
    z-index:1;
    cursor:pointer;
    &:hover, &.selected{
        transform:scale(1.5);
    }
    &:hover div, &.selected div{
        margin-left:15px;
        margin-top:15px;
        height:0px;
        width:0px;
    }
    &:hover p, &.selected p{
        color:white;
        font-size: 16px;
    }
    &:hover ${MapMarkTitle}, &.selected ${MapMarkTitle}{
        opacity:1;
        top:32px;
        transform:scale(1);
    }
`







const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getMapData:(arg)=>dispatch(getMapData(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        MAP_MARKS : store.page.MAP_MARKS,
        MAP_LAYERS : store.page.MAP_LAYERS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Map)

