import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { getScaned } from '../../../actions/PageActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'
import POP_arendator from '../Arendators/POP_arendator';

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../components/Table';
import POP_slider from './POP_slider';
import { logEvent } from '@firebase/analytics';
import Texts from '../Texts';


class Sliders extends React.Component {
  constructor(props) {
      super()
      this.state = {
        filter:null
      } 
      
  }
  componentDidMount(){
    logEvent(global.analytics, 'open_page', {
      data: 'Sliders' 
    })
   
  }

  componentWillUnmount(){
    
  }


  render (){
    const {filter} = this.state
    const {SLIDERS} = this.props

    return (
      <MainView>
        <h4>Изображения и слайдеры</h4>
        <div className='row row-cols-lg-6 row-cols-md-4 mt-4'>
          {SLIDERS.map(slider =>
              <div className='col'>
                <Card.View onClick={()=>this.props.openPopUp(//окошко редактирования
                    <POP_slider ID_OBJECT={slider.ID} multiple={slider.IS_MULTIPLE} />
                  )}>
                  
                  <h5>{slider.TITLE}</h5>

                  <Card.Slide AllCount={slider.IMAGES_URL.length} src={`${global.URL_host}img/${slider.IMAGES_URL[0]}`}/>
                  {slider.IMAGES_URL.length<=1?null:
                    slider.IMAGES_URL.map((slide,index)=>
                      index==0?null:
                      <Card.SlideMini AllCount={slider.IMAGES_URL.length-1} index={index-1} src={`${global.URL_host}img/${slide}`}/>
                    )
                  }
                </Card.View>
              </div>
            )}
        </div>
        <hr/>
        <h4>Текстовые значения</h4>
        <Texts/>
      </MainView>
      )
  } 
}

const Card = {
  View:styled.div`
  padding:10px;
  background:white;
  box-shadow:10px 10px 30px rgba(0,0,0,0.05);
  border-radius:10px;
  cursor:pointer;
  margin-bottom:20px;
  position:relative;
  transition:.3s;
  float: left;
width: 100%;

  & h5{
    font-size:12px;
    height:30px;
  }
  &:hover{
    transform:scale(1.01);
  }
  `,
  Slide:styled.img`
  width: 100%;
  height: ${props=>props.AllCount==1?'125px':'70px'};
  object-fit: cover;
  border-radius: 10px;
  `,
  SlideMini:styled.img`
  margin-top:5px;
  width: calc((100% - ${props=>props.AllCount-1} * 5px) / ${props=>props.AllCount});
  height: 50px;
  margin-left:${props=>props.index==0?'0px':'5px'};
  object-fit: cover;
  border-radius: 10px;
  `
}

const MainView = styled.div`

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getScaned:(arg)=>dispatch(getScaned(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SLIDERS : store.page.SLIDERS
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Sliders)

