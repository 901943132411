import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getArendators ,getARENDATORS_STARS} from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import POP_createArendUser from './POP_createArendUser';
import {withToast} from '../../components/Toasts'
import { logEvent } from '@firebase/analytics';
import Input_image from '../../components/Input_image';
import { Button } from '@mui/material';


class POP_comment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           
            Self:{},
        }
       
    }

    componentDidMount(){
      
        if(this.props.ID_OBJECT!=null)
           this.getSelf()
    }

    getSelf(){
        
        let Self = this.props.ARENDATORS_STARS.find(arend => arend.ID == this.props.ID_OBJECT)
        
        if(Self==null) Self={}
        
        this.setState({Self})
        
    }


    Delete(){
        GlobalFunctions.API_task({
            operation:'deleteSome',
            ID:this.props.ID_OBJECT,
            Table:'ARENDATORS_STARS'
        }).then(res=>{
            this.props.getArendators()
            this.props.getARENDATORS_STARS()
            this.props.closePopUp()
        })
    }


    isOK(){
        GlobalFunctions.API_task({
            operation:'moderComment',
            ID:this.props.ID_OBJECT
        }).then(res=>{
            this.props.getArendators()
            this.props.getARENDATORS_STARS()
            this.props.closePopUp()
        })
    }
    


    render (){
        const {filter, Representors,Self} = this.state
        const {Arendators, SHOP_CATEGORIES, AREND_STATUSES,ID_OBJECT} = this.props
        
        if(Self.ID==null && ID_OBJECT!=null) return <div/>

        return (
            <POP> 
                <h3>Отзыв</h3>
               
                <b>Арендатор</b>
                <p>{Self.TITLE}</p>
                <hr/>

                <b>Клиент</b>
                <p>{Self.FIO}</p>
                <p>{GlobalFunctions.phoneFormatter(Self.PHONE)}</p>
                <p><a href={"mailto:"+Self.EMAIL}>{Self.EMAIL}</a></p>
                <hr/>

                <b>Отзыв</b>
                <p>{Self.STARS}</p>
                <p>{Self.COMMENT}</p>
                <br/>
                
                <Button className='me-1' variant='text' size='small' color='success' onClick={()=>{
                    this.isOK()}
                }>Подтвердить</Button>
     

                <Button className='me-1 float-end' variant='text' size='small' color='error' onClick={()=>{
                     this.Delete()
                }}>Удалить</Button>
                
            </POP>
        )
    }
}


const POP = styled.div`
width:300px;
height:auto;
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getArendators:(arg)=>dispatch(getArendators(arg)),

        getARENDATORS_STARS:(arg)=>dispatch(getARENDATORS_STARS(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        Arendators:store.page.Arendators,
        SHOP_CATEGORIES:store.page.SHOP_CATEGORIES,
        ARENDATORS_STARS:store.page.ARENDATORS_STARS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(POP_comment));

