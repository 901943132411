import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getArendators } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import POP_createArendUser from './POP_createArendUser';
import {withToast} from '../../components/Toasts'
import { logEvent } from '@firebase/analytics';
import Input_image from '../../components/Input_image';
import { Button } from '@mui/material';
const form_fields = {
    main : [
        {
            title:'Наименование',
            placeholder:'',
            name:'TITLE'
        },
        {
            title:'Описание',
            placeholder:'',
            name:'DESCR',
            type:'textarea'
        },
        {
            title:'Телефон для клиентов',
            placeholder:'+7',
            name:'PHONE'
        },
        {
            title:'Почта для клиентов',
            placeholder:'ex@mail.ru',
            name:'EMAIL'
        },
        {
            title:'Дата начала аренды',
            placeholder:'',
            name:'AREND_DATE_START',
            type:'date'
        },
        {
            title:'Дата окончания аренды',
            placeholder:'',
            name:'AREND_DATE_END',
            type:'date'
        },
        {
            title:'Выремя открытия',
            placeholder:'8:00',
            name:'START_TIME',
            type:'time'
        },
        {
            title:'Время закрытия',
            placeholder:'23:00',
            name:'END_TIME',
            type:'time'
        }
    ],
    persons : [
        {
            title:'ФИО',
            placeholder:'',
            name:'FIO'
        },
        {
            title:'Должность',
            placeholder:'',
            name:'POST'
        },
        {
            title:'Коментарий',
            placeholder:'',
            name:'COMMENT'
        },
        {
            title:'Почта для связи',
            placeholder:'ex@mail.ru',
            name:'EMAIL'
        },
        {
            title:'Номер телефона',
            placeholder:'+7',
            name:'PHONE'
        }
    ]
}




class POP_arendator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter:null,
            ID_OBJECT:props.ID_OBJECT,
            Representors:[],
            Self:{},
        }
        this.input={
            main:{},
            persons:{}
        }

        this.filesToUpload1=[]
        this.filesToDelete1=[]

        this.filesToUpload2=[]
        this.filesToDelete2=[]
        
    }

    componentDidMount(){
        logEvent(global.analytics, 'open_page', {
            data: 'POP_arend' 
        })
        if(this.state.ID_OBJECT==null){
            this.createUpdateArendator()
                .then(()=>this.getRepresentors())
            this.input.main.ID_STATUS.value=1
        }else{
            this.getSelf()
            this.getRepresentors()
        }

        setTimeout(()=>{
            GlobalFunctions.maskPhone(this.input.main.PHONE)
            
        },300)
    }

    getSelf(){
        
        let Self = this.props.Arendators.find(arend => arend.ID == this.state.ID_OBJECT)
        
        this.setState({Self})
        setTimeout(()=>{
           
            Object.keys(this.input.main).forEach(key =>{

                this.input.main[key].value = Self[key]
            })
        },1)
    }

    

    getRepresentors(){
        GlobalFunctions.API_task({
            operation:'getRepresentors',
            ID_AREND:this.state.ID_OBJECT
        }).then(res=>{
            this.setState({Representors:res})
        })
    }

    

    createUpdateArendator(data){
        return new Promise((resolve,reject)=>{
            let reqv = {
                operation:'createUpdateArendator',
            }
            if(data!=null){
                reqv = {
                    ...data,
                    ...reqv
                }
            }

            GlobalFunctions.API_task(reqv).then(res=>{
                logEvent(global.analytics, 'save_arendator', {
                    data:res,
                })
                this.setState({ID_OBJECT:res.ID})
                this.props.getArendators()
                setTimeout(resolve,1)
                this.props.addToast('Сохранено', { appearance: 'success',autoDismiss:true });
            }).catch(reject)
        }) 
    }

    async Save(){
        if(!GlobalFunctions.checkReqvireds([this.input.main.TITLE,this.input.main.SHOP_CATEGORY_ID])) return

        let data = {}
        Object.keys(this.input.main).forEach(key=>{
            let val = this.input.main[key].value
            data[key] = val.length == 0 ? null : val
        })

        data.PHONE=GlobalFunctions.getClearPhone(data.PHONE)
        data.EMAIL=data.EMAIL.replaceAll(' ','')

       

        let promises =[]
        if(this.filesToUpload1.length>0)
            promises.push(GlobalFunctions.API_files({
                operation:'setIMAGE',
                Table:'ARENDATORS',
                OtherFieldName:'URL_LOGO',
                ID:this.state.ID_OBJECT,
            },this.filesToUpload1))

        if(this.filesToDelete1.length>0)
            promises.push(GlobalFunctions.API_task({
                operation:'deleteIMAGE',
                ID:this.state.ID_OBJECT,
                OtherFieldName:'URL_LOGO',
                Table:'ARENDATORS',
                filesToDelete:JSON.stringify(this.filesToDelete1,1)
            }))
        
        if(this.filesToUpload2.length>0)
            promises.push(GlobalFunctions.API_files({
                operation:'setIMAGE',
                OtherFieldName:'URL_BG',
                Table:'ARENDATORS',
                ID:this.state.ID_OBJECT,
            },this.filesToUpload2))

        if(this.filesToDelete2.length>0)
            promises.push(GlobalFunctions.API_task({
                operation:'deleteIMAGE',
                OtherFieldName:'URL_BG',
                ID:this.state.ID_OBJECT,
                Table:'ARENDATORS',
                filesToDelete:JSON.stringify(this.filesToDelete2,1)
            }))

        await Promise.all(promises).then(()=>{
            this.createUpdateArendator({
                data,
                ID:this.state.ID_OBJECT
            }).then(()=>{
                this.props.closePopUp()
            })
        })
        
    }

    SavePerson(ID_REPRESENTOR){
        if(!GlobalFunctions.checkReqvireds(this.input.persons,['PHONE','EMAIL','POST','COMMENT'])) return
        let data = {}
        Object.keys(this.input.persons).forEach(key=>{
            let val = this.input.persons[key].value
            data[key] = val.length == 0 ? null : val
        })
        
        let Reqv = {
            operation:'createUpdateRepresentor',
            data,
            ID_AREND:this.state.ID_OBJECT
        }

        if(ID_REPRESENTOR!=null)
             Reqv.ID = ID_REPRESENTOR
        
        
        GlobalFunctions.API_task(Reqv).then(res=>{
            
            if(res.ID!=null){
                this.props.closePopUp()
                this.getRepresentors()
            }
        })
    }

    DeletePerson(ID_REPRESENTOR){
        GlobalFunctions.API_task({
            operation:'deleteElement',
            Table:'AREND_REPRESEND_PERSONS',
            ID:ID_REPRESENTOR
        }).then(res=>{
            logEvent(global.analytics, 'delete_person', {
                data:{
                    ID_REPRESENTOR,
                    ID_AREND:this.props.ID_OBJECT
                },
            })
                this.props.closePopUp()
                this.getRepresentors()
        })
    }

    CreateAK(){
        this.props.openPopUp(
            <POP_createArendUser
            callBack={()=>{
                
                this.props.addToast('Письмо успешно отправлено', { appearance: 'success',autoDismiss:true });
                this.props.getArendators()
                setTimeout(()=>{
                    this.getSelf()
                },500)
                
            }}
            ID_AREND={this.state.ID_OBJECT}/>
        )
    }

    Delete(){
        GlobalFunctions.API_task({
            operation:'deleteElement',
            Table:'ARENDATORS',
            ID:this.state.ID_OBJECT
        }).then(res=>{
            logEvent(global.analytics, 'delete_arend', {
                data:this.state.ID_OBJECT,
            })
            this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
                this.props.closePopUp()
                this.props.getArendators()
        })
    }

    render (){
        const {filter, Representors,Self} = this.state
        const {Arendators, SHOP_CATEGORIES, AREND_STATUSES} = this.props
        
        return (
            <POP> 
                <div className='row'>
                    <div className='col-5'>
                        <h3>Арендатор</h3>
                        <ComboBox
                        name='SHOP_CATEGORY_ID'
                        title='Категрия'
                        Ref={this.input.main}>
                            {SHOP_CATEGORIES.map(data=>
                                    <option value={data.ID}>{data.TITLE}</option>
                                )}
                        </ComboBox>
                        

                        <ComboBox
                        name='ID_STATUS'
                        title='Статус'
                        Ref={this.input.main}>
                            {AREND_STATUSES.map(data=>
                                    <option  value={data.ID}>{data.TITLE}</option>
                                )}
                        </ComboBox>
                        {form_fields.main.map(input => 
                            <Input    
                            {...input}
                            Ref={this.input.main}/>
                        )}


                    {Self.ID_USER==null?null:
                        <User.View className='btn'>
                            <User.Login>{Self.USER_LOGIN}</User.Login>
                            <User.Status>{Self.USER_STATUS_TITLE}</User.Status>
                        </User.View>
                    }
                    </div>
                    <div className='col-4 pt-2'>
                        <h5 className='float-left d-inline'>Представители</h5>

                            <Button className='float-left d-inline ms-2' variant='text' size='small' onClick={()=>{
                                this.props.openPopUp(
                                    <Representor
                                    Save={()=>this.SavePerson()}
                                    Context={this}/>
                                )
                            }}>Добавить</Button>
                        <Scroll>
                            {Representors.map(rep => 
                                <Rep onClick={()=>{
                                    this.props.openPopUp(
                                        <Representor
                                        ID_OBJECT={rep.ID}
                                        Save={()=>this.SavePerson(rep.ID)}
                                        Delete={()=>this.DeletePerson(rep.ID)}
                                        CreateAK={()=>this.CreateAK()}
                                        Context={this}/>
                                    )
                                }}>
                                    <p>{rep.FIO}</p>
                                    <small>{rep.POST}</small>
                                </Rep>
                            )}
                        </Scroll>
                    </div>
                    <div className='col-3 pt-2'>
                        <h5 className='float-left d-inline mb-5'>Медиа</h5>
                        <br/>
                        <small>Лого</small>
                        <Input_image multiple={false} value={Self.URL_LOGO} onChange={(filesToUpload,filesToDelete)=>{
                                
                                this.filesToUpload1=filesToUpload
                                this.filesToDelete1=filesToDelete
                                
                            }}/>

                        <small className="mt-3 d-block">Фон</small>
                        <Input_image multiple={false} value={Self.URL_BG} onChange={(filesToUpload,filesToDelete)=>{
                                
                                this.filesToUpload2=filesToUpload
                                this.filesToDelete2=filesToDelete
                                
                            }}/>
                    </div>
                </div>
                
                <Button className='me-1' variant='outlined' size='small'onClick={()=>{
                    this.Save()
                }}>Сохранить</Button>


           
                {Self.ID_USER==null?
                    <Button className='me-1' variant='text' size='small' onClick={()=>{
                        this.CreateAK()}
                    }>Создать акаунт</Button>
                :null}

                <Button className='me-1 float-end' variant='text' size='small' color='error' onClick={()=>{
                     this.Delete()
                }}>Удалить</Button>
                
            </POP>
        )
    }
}

const User = {
    View:styled.div`
    background: rgb(250,250,250);
border-radius: 10px;
padding: 5px 10px;
border: 2px solid rgb(245,245,245);
width: max-content;
display: inline-block;
margin-left:0px;
margin-bottom:20px;
    `,
    Login:styled.p`
    margin: 0;
    font-size: 14px !important;
    font-weight:600;
    `,
    Status:styled.small`
    margin: 0;
font-size: 11px;
margin-top: -5px;
display: block;
    `

}

const Representor = (props)=>{
    const POP_R = styled.div`
    width:250px;
    height: 346px;
    `

    setTimeout(()=>{
        try{
            if(props.ID_OBJECT != null ){
                Object.keys(props.Context.input.persons).forEach(key =>{
                    let Rep_elem= props.Context.state.Representors.filter(Rep_ => Rep_.ID == props.ID_OBJECT)[0]
                    props.Context.input.persons[key].value = Rep_elem[key]
                    
                })
            }
        }catch(e){}
    },1)
    

    return (
        <POP_R> 
            <h5>Представитель</h5>
            {form_fields.persons.map(input => 
           
                <Input    
                {...input}
                Ref={props.Context.input.persons}/>
            )} 

                <Button className='me-1' variant='outlined' size='small'onClick={props.Save}>Сохранить</Button>
                {props.ID_OBJECT==null?null:
                    <Button className='me-1 float-end' variant='text' color='error' size='small'onClick={props.Delete}>Удалить</Button>
                }

            
        </POP_R>
    )
}

const POP = styled.div`
width:950px;
height:auto;
`

const Scroll = styled.div`
overflow-y:auto;
height:550px
`

const Rep = styled.div`
margin-bottom:5px;
margin-top:10px;
cursor:pointer;
& p{
    font-size: 14px;
margin-bottom: 0px;
}

& small{
    margin-top: -6px;
display: block;
}
`

const BtnLeft = styled.div`

`
const BtnRight = styled.div`
float:right;
`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getArendators:(arg)=>dispatch(getArendators(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        Arendators:store.page.Arendators,
        SHOP_CATEGORIES:store.page.SHOP_CATEGORIES,
        AREND_STATUSES:store.page.AREND_STATUSES,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(POP_arendator));

