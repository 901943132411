import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  } from '../../../actions/PopUpActions';
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import {getTexts} from "../../../actions/PageActions"
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import { Label } from '../../components/moduls/Input';




class Texts extends React.Component {
    constructor(props) {
        super()
        this.state = {
            selectedText:null
        }

        this.texts={}
        this.TEXT={}

    }

    

    componentDidMount() {
       
    }

    componentWillReceiveProps(nextProps){
        let {selectedText} = this.state
        
        if(selectedText!=null){
            selectedText = nextProps.TEXTS[selectedText.NAME] 
            this.setState({selectedText})
        }
    }

    renderTexts(){
        const {TEXTS} = this.props
        return Object.keys(TEXTS).map(key=>
            <TextItem className="col" data={TEXTS[key]} selectText={(data)=>this.setState({selectedText:data})}/>
        )
    }

    createUpdateWorkTimes(ID,OLD_TEXT_JSON){
        
        OLD_TEXT_JSON.push({
            "TEXT":"",
            "TIME":""
        })
       
        this.updateTexts(ID,JSON.stringify(OLD_TEXT_JSON))
    }

    updateTexts(ID,TEXT){
        GF.API_task({
            operation:"updateTexts",
            TEXT,
            ID
        })
        .then(res => {
            this.props.getTexts()
            this.setState({selectedText:null})
        })
    }

    deleteWorkTimes(ID,OLD_TEXT_JSON,indexToDelete){
        OLD_TEXT_JSON.splice(indexToDelete,1)
        this.updateTexts(ID,JSON.stringify(OLD_TEXT_JSON))
    }

    deleteBillet(ID,OLD_TEXT_JSON,i,b){
        OLD_TEXT_JSON[i].TEXT.splice(b,1)
        this.updateTexts(ID,JSON.stringify(OLD_TEXT_JSON))
    }


    createBillet(ID,OLD_TEXT_JSON,indexToInsert){
        
        OLD_TEXT_JSON[indexToInsert].TEXT.push({
            "TEXT":"",
            "COAST":""
        })
        this.updateTexts(ID,JSON.stringify(OLD_TEXT_JSON))
    }

    createBilletType(ID,OLD_TEXT_JSON){
        
        OLD_TEXT_JSON.push({
            TYPE:"",
            TEXT:[{
                "TEXT":"",
                "COAST":""
            }]
        })
        this.updateTexts(ID,JSON.stringify(OLD_TEXT_JSON))
    }
   

    renderText(selectedText){
        let cont=null
        this.texts={}
        
        switch(selectedText.NAME){
            case "BilletCoasts": 
                cont=[] 
                var BilletCoasts = JSON.parse(selectedText.TEXT)
                this.TEXT=BilletCoasts

                BilletCoasts.map((item,i)=>{
                    
                    
                    cont.push([
                        <hr className="mt-3"/>,
                        <Small className="mt-3" onClick={()=>this.deleteWorkTimes(selectedText.ID,BilletCoasts,i)}>Удалить весь тип</Small>,

                        <input onKeyUp={(e)=>{
                            let JSON_TEXT = BilletCoasts
                            JSON_TEXT[i]={
                                ...JSON_TEXT[i],
                                TYPE:e.target.value
                            }
                            this.TEXT=JSON_TEXT
                            }}
                            ref={ref=>this.texts[`type${i}`]=ref}
                            placeholder="Название типа билета"
                            className="form-control mb-3 "/>
                    ])
                    
                   
                        


                         
                    item.TEXT.map((bilet,b)=>{

                        cont.push([
                            <Small className="mt-1" onClick={()=>this.deleteBillet(selectedText.ID,BilletCoasts,i,b)}>Удалить билет</Small>,

                            <input onKeyUp={(e)=>{
                                let JSON_TEXT = BilletCoasts
                                JSON_TEXT[i].TEXT[b]={
                                    ...JSON_TEXT[i].TEXT[b],
                                    TEXT:e.target.value
                                }
                                this.TEXT=JSON_TEXT
                                }}
                                ref={ref=>this.texts[`text${i}_${b}`]=ref}
                                placeholder="Название билета"
                                className="form-control"/>,

                            <input onKeyUp={(e)=>{
                                let JSON_TEXT = BilletCoasts
                                JSON_TEXT[i].TEXT[b]={
                                    ...JSON_TEXT[i].TEXT[b],
                                    COAST:e.target.value
                                }
                                this.TEXT=JSON_TEXT
                                }}
                                ref={ref=>this.texts[`coast${i}_${b}`]=ref}
                                placeholder="Цена билета"
                                className="form-control mb-2"/>
                        ])

                    })

                    cont.push(<div
                        className="btn btn-sm btn-light mb-2"
                        onClick={()=>this.createBillet(selectedText.ID,BilletCoasts,i)}>Добавить билет</div>)

                    cont.push(<br/>)
                })

                cont.push(<div
                    className="btn btn-sm btn-primary mt-2"
                    onClick={()=>this.createBilletType(selectedText.ID,BilletCoasts)}>Добавить тип билета</div>)
                
                setTimeout(()=>{
                    
                    BilletCoasts.map((item,i)=>{
                        this.texts[`type${i}`].value=item.TYPE
                        item.TEXT.map((bilet,b)=>{
                            this.texts[`text${i}_${b}`].value=bilet.TEXT
                            this.texts[`coast${i}_${b}`].value=bilet.COAST
                        })
                    })
                    
                },1)
            break
            case "WorkTimes":
                cont=[] 
                var WorkTimes = JSON.parse(selectedText.TEXT)
                this.TEXT=WorkTimes

                WorkTimes.map((item,i)=>{
                    
                    cont.push([
                        <hr className="mt-3"/>,
                        <Small onClick={()=>this.deleteWorkTimes(selectedText.ID,WorkTimes,i)}>Удалить</Small>,
                        <input onKeyUp={(e)=>{
                            let JSON_TEXT = WorkTimes
                            JSON_TEXT[i]={
                                ...JSON_TEXT[i],
                                TEXT:e.target.value
                            }
                            this.TEXT=JSON_TEXT
                        }}
                        ref={ref=>this.texts[`text${i}`]=ref}
                        placeholder="Название места"
                        className="form-control"/>,

                        <input onKeyUp={(e)=>{
                            let JSON_TEXT = WorkTimes
                            JSON_TEXT[i]={
                                ...JSON_TEXT[i],
                                TIME:e.target.value
                            }
                            this.TEXT=JSON_TEXT
                        }}
                        ref={ref=>this.texts[`time${i}`]=ref}
                        placeholder="Время работы"
                        className="form-control"/>,

                        <br/>
                    ])

                })

                cont.push(<div className="btn btn-sm btn-light" onClick={()=>this.createUpdateWorkTimes(selectedText.ID,WorkTimes)}>Добавить расписание</div>)
                
                setTimeout(()=>{
                     
                    WorkTimes.map((item,i)=>{
                        this.texts[`text${i}`].value=item.TEXT
                        this.texts[`time${i}`].value=item.TIME
                    })
                    
                },1)
                
                
            break
            default:
                cont= <Textarea
                    onKeyUp={(e)=>this.TEXT=e.target.value}
                    ref={ref=>this.texts.text=ref}
                    className="form-control"/>
                setTimeout(()=>{this.texts.text.value=JSON.parse(selectedText.TEXT)},1)
            break
        }
        

        return (
            <div className="col">
                <div className="btn btn-sm btn-primary mb-1" onClick={()=>{
                    this.updateTexts(selectedText.ID,JSON.stringify(this.TEXT))
                }}>Сохранить изменения</div>
                {cont}
            </div>
        )
    }


    render (){
        const {selectedText} = this.state
        
        return (
            <Cont className="container-fluid p-0 m-0">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {selectedText==null?
                            <li className="breadcrumb-item"> </li>
                            :
                            <li className="breadcrumb-item" onClick={()=>this.setState({selectedText:null})}><a className='btn btn-light btn-ms'>Назад </a> {selectedText.TITLE}</li>
                        }
                    </ol>
                </nav>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 p-0">
                    {selectedText==null?
                        this.renderTexts()
                        :
                        this.renderText(selectedText)
                    }
                </div>
                
            </Cont>
        )
    }
}



const Textarea=styled.textarea`
    resize:none;
    height:120px;
    width:500px;
`


const Small=styled.p`
    font-size:11px;
    color:gray;
    cursor:pointer;
    margin-bottom:0px;
`

const Cont = styled.div`

`

const Item = styled.div`
    border-radius:10px;
    background:white;
    box-shadow:10px 10px 30px rgba(0,0,0,0);
    padding:20px;
    border:1px solid rgba(0,0,0,0.03);
    font-size:14px;
    margin:20px;
    cursor:pointer;
    transition:.3s;
    &:hover{
        box-shadow:10px 10px 30px rgba(0,0,0,0.05);
    }
    `
const TextItem =(props)=>{
    

    return(
        <Item onClick={()=>props.selectText(props.data)}>
            {props.data.TITLE}
            <Label style={{
                marginTop:5,
                height:20,
                overflow:'hidden'
            }}>{props.data.TEXT}</Label>
        </Item>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        getTexts:()=>dispatch(getTexts())
    };
  };


const mapStateToProps = (store) => {
   
    return {
        TEXTS:store.page.TEXTS
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Texts));

