import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getScaned } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import POP_arendator from '../Arendators/POP_arendator';
import Viewer from 'react-viewer';
import { logEvent } from '@firebase/analytics';
import POP_setScanNominal from './POP_setScanNominal';
import { Button } from '@mui/material';




class POP_scan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter:null,
            ID_OBJECT:props.ID_OBJECT,
            imgViewerIsVisible:false,
            Self:{},
            activeIndex:0
            
        }
        this.input={
            main:{},
            persons:{},
        }

        
        this.form_values = [
            {
                title:'Статус',
                key:'STATUS_TITLE'
            },
            {
                title:'Номер чека',
                key:'CHECK_NUMBER'
            },
            {
                title:'Дата сканирования',
                key:'DATE_TIME_SCANED',
                renderFunction:(value)=>{return GlobalFunctions.makeNormalDate(value,'dd.mm.yyyy H:M')}
            },
            {
                title:'Дата покупки',
                key:'DATE_TIME_BAY',
                renderFunction:(value)=>{return GlobalFunctions.makeNormalDate(value,'dd.mm.yyyy H:M')}
            },
            {
                title:'Номинал',
                key:'COAST',
                renderFunction:(value)=>{return GlobalFunctions.priceWithSpaces(value)+' руб'}
            },
            {
                title:'Магазин',
                key:'AREND_TITLE',
                onClick:(e)=>{this.props.openPopUp(<POP_arendator ID_OBJECT={e.ID_AREND} />)}
            },
            {
                title:'Категоирия',
                key:'AREND_SHOP_CATEGORY_TITLE'
            },
            {
                title:'Телефон арендатора',
                key:'AREND_PHONE',
                renderFunction:(value)=>{return '+'+value}
            },
            {
                title:'Почта арендатора',
                key:'AREND_EMAIL'
            },
            {
                title:'Клиент',
                key:'USER_FIO'
            },
            {
                title:'Телефон клиента',
                key:'USER_PHONE',
                renderFunction:(value)=>{return '+'+value}
            },
            {
                title:'Почта клиента',
                key:'USER_EMAIL'
            },
            {
                title:'Уровень клиента',
                key:'USER_LVL'
            },
            {
                title:'Баллы клиента',
                key:'USER_EXP'
            }
        ]
    }

    shouldComponentUpdate(nextProps,nextState){
        let SelfNew = nextProps.SCANED.find(data => data.ID == this.state.ID_OBJECT)
        if(SelfNew.ID_STATUS != this.state.Self.ID_STATUS || nextState!=this.state){
            return true
        }else
            return false
    }

    componentDidMount(){
        logEvent(global.analytics, 'open_page', {
            data: 'POP_scan' 
          })
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    async AproveScan(){
        const {Self} = this.state
        let canSave = false
        if(Self.COAST==null || Self.COAST == 0){
            //если не указан номинал чека
            await new Promise((resolve,reject)=>{
                this.props.openPopUp(<POP_setScanNominal ID_OBJECT={Self.ID} callBack={(canSave_comand)=>{
                    
                    canSave=canSave_comand
                    resolve()
                }} />)
            })
        }else
            canSave=true
        
        if(!canSave) return

        GlobalFunctions.API_task({
            operation:'AproveScan',
            ID_SCAN:this.props.ID_OBJECT
        }).then(data => {
            logEvent(global.analytics, 'sacn_set', {
                data:{
                    type:'AproveScan',
                    id:this.props.ID_OBJECT
                },
            })
            this.props.getScaned()
            this.props.closePopUp()
        })  
    }

    CanselScan(){
        GlobalFunctions.API_task({
            operation:'CanselScan',
            ID_SCAN:this.props.ID_OBJECT
        }).then(data => {
            logEvent(global.analytics, 'sacn_set', {
                data:{
                    type:'CanselScan',
                    id:this.props.ID_OBJECT
                },
            })
            this.props.getScaned()
            this.props.closePopUp()
        })  
    }

    getSelf(){
        
        let Self = this.props.SCANED.find(data => data.ID == this.state.ID_OBJECT)
        
        this.setState({Self})
        logEvent(global.analytics, 'open_object', {
            data: 'POP_scan',
            object:Self
          })
        setTimeout(()=>{
           
            Object.keys(this.input.main).forEach(key =>{
                if(this.input.main[key].type=='date' || this.input.main[key].type =='datetime-local'){
                    let val = GlobalFunctions.isStringDateTime(Self[key]) ? 
                        Self[key].replace(' ','T'):
                        Self[key]

                    this.input.main[key].value = val
                }else
                    this.input.main[key].value = Self[key]
                
            })
        },1)
    }

   




    render (){
        const {Self,imgViewerIsVisible,activeIndex} = this.state
        const {ID_OBJECT} = this.props
        
        return (
            <POP> 
                <h4>Отсканированнаый чек</h4>
                <div className='row mt-2'>
                    <div className='col-6'>
                        
                        {this.form_values.map(data=>
                            <p  onClick={()=>{try{data.onClick(Self)}catch(e){debugger}}}>
                                <b>{data.title}</b>
                                <k style={data.onClick==null?null:{
                                    background:'rgb(240,240,240)',
                                    padding:'5px 10px',
                                    borderRadius:10,
                                    cursor:'pointer'
                                }}>
                                {data.renderFunction==null?Self[data.key] : data.renderFunction(Self[data.key])}
                                </k>
                            </p>
                        )}
                        

                        
                        
                    </div>
                    <div className='col-6' style={{position:'relative'}}>
                        <Scroll>
                           
                                {Self.IMAGES_URL==null?null:Self.IMAGES_URL.map((img,index)=>
                                    <Check src={`${global.URL_host}img/${img}`} onClick={()=>this.setState({imgViewerIsVisible:true,activeIndex:index})}/>
                                )}
                        </Scroll>

                        {Self.IMAGES_URL==null?null:
                            <Viewer
                            minScale={0.9}
                            maxScale={3}
                            activeIndex={activeIndex}
                            visible={imgViewerIsVisible}
                            onClose={() => this.setState({imgViewerIsVisible:false}) }
                            images={Self.IMAGES_URL.map(img=>{
                                return{
                                    src:`${global.URL_host}img/${img}`
                                }
                            })}
                            />
                        }
                        
                    </div>
                </div>

             
                {Self.ID_STATUS==2 || Self.ID_STATUS==4 || Self.ID_STATUS==null ? null:
                    Self.ID_STATUS==3 || Self.ID_STATUS==1?[
                        <Button className='me-1' variant='outlined' size='small' onClick={()=> this.AproveScan()}>Подтвердить</Button>,
                        <Button className='me-1' variant='outlined' size='small' color='error' onClick={()=> this.CanselScan()}>Отказать</Button>,
                    ]
                :null}
                
            </POP>
        )
    }
}


const POP = styled.div`
width:750px;
height:auto;
& p{
    font-size:12px;
}
& p b{
    font-size:14px;
    width:180px;
    font-weight:500;
    display:inline-block;
}
`

const Check = styled.img`
width:100%;
height:auto;
margin-bottom:20px;
border-radius:20px;
object-fit:contain;
display:block;
`

const Scroll = styled.div`
width: calc(100% - 20px);
height: calc(100% - 20px);
overflow: auto;
position: absolute;
border-radius: 20px;
padding-bottom: 100px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),

        getScaned: (arg) => dispatch(getScaned(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        SCANED_STATUSES : store.page.SCANED_STATUSES,
        SCANED: store.page.SCANED,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_scan);

