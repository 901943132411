import map0 from './map0-min.svg'
import map1 from './map1-min.svg'
import map2 from './map2-min.svg'
import map3 from './map3-min.svg'
import map4 from './map4-min.svg'
import map5 from './map5-min.svg'

export const MapSVG = [
    map0,
    map1,
    map2,
    map3,
    map4,
    map5,
]