import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getArendators } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';

const form_fields = {
    main : [
        {
            title:'Email аккаунта арендатора',
            placeholder:'',
            type:'email',
            name:'EMAIL'
        }
    ]
}




class POP_createArendUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
        this.input={
        }
        
    }

    componentDidMount(){
        logEvent(global.analytics, 'open_page', {
            data: 'POP_create_user_arend' 
        })
    }

    

    Create(){
        this.setState({error:null})
        if(!GlobalFunctions.checkReqvireds(this.input)) return

        this.input.EMAIL.value=this.input.EMAIL.value.toLowerCase()
        GlobalFunctions.API_task({
            operation:'createArendUser',
            EMAIL:this.input.EMAIL.value,
            ID_AREND:this.props.ID_AREND
        }).then(res=>{
            if(res.error==null){
                logEvent(global.analytics, 'create_user_arend_done', {
                    data:res
                })
                this.props.closePopUp()
                this.props.callBack()
            }else{
                logEvent(global.analytics, 'create_user_arend_error', {
                    error:res.error
                })
                this.setState({error:res.error})
                this.input.EMAIL.classList.add("border-danger");
            }
        })
    }


    render (){
        const {filter, Representors,Self,error} = this.state
        const {Arendators, SHOP_CATEGORIES, AREND_STATUSES} = this.props
        
        return (
            <POP> 
                <div className='row'>
                    <div className='col-12'>
                        <h5 className='mb-3'>Создать аккаунт для арендатора</h5>
                        
                        {form_fields.main.map(input => 
                            <Input    
                            {...input}
                            Ref={this.input}/>
                        )}

                        {error==null?null:
                            <ErrorText>{error}</ErrorText>
                        }
                    </div>
                    
                </div>
                
                <Button className='me-1' variant='outlined' size='small'onClick={()=>{
                    this.Create()
                }}>Создать</Button>
               

            </POP>
        )
    }
}

const POP = styled.div`
width:350px;
height:140px
`

const ErrorText = styled.p`
position: absolute;
font-size: 11px;
color: rgb(198, 32, 32);
margin-top: -9px;
margin-left: 11px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getArendators:(arg)=>dispatch(getArendators(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        Arendators:store.page.Arendators,
        SHOP_CATEGORIES:store.page.SHOP_CATEGORIES,
        AREND_STATUSES:store.page.AREND_STATUSES,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_createArendUser);

