import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'
import BackImg from '../../../design/bg.jpg'
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';


class KeyCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          KeyData:{}
        }
        this.input={}
        this.checkKey()
    }

    componentDidMount(){
      logEvent(global.analytics, 'open_page', {
        data: 'KeyCode' 
      })

      
    }

    checkKey(){
      GlobalFunctions.API_task({
        operation:'checkKey',
        block:'Auth',
        GUID:this.props.match.params.GUID
      }).then(res=>{
        this.setState({KeyData:res})

        logEvent(global.analytics, 'key_code_open', {
          data:res
        })

        setTimeout(() => {
          try{
            GlobalFunctions.maskPhone(this.input.PHONE)
          }catch(e){}
        }, 10);
      }).catch(er=>{
        this.setState({errorMSG:er})
        logEvent(global.analytics, 'key_code_broken', {
          error:er,
          data:this.props.match.params.GUID
        })
      })
    }

    Create() {
      const {KeyData} =this.state

      this.setState({errorMSG:null})
      if(!GlobalFunctions.checkReqvireds(this.input)) return

      this.input.EMAIL.value=this.input.EMAIL.value.replaceAll(' ','')

      
      if(KeyData.KEY_TYPE=='ARREG'){
        if(this.input.PASSWORD.value!=this.input.PASSWORD2.value){
          this.setState({errorMSG:'Пароли не совпадают'})
          return
        }

        this.input.EMAIL.value=this.input.EMAIL.value.toLowerCase()
        
        GlobalFunctions.API_task({
            operation:'confimEmailUser',
            block:'Auth',
            EMAIL:this.input.EMAIL.value,
            PASSWORD:this.input.PASSWORD.value,
            GUID:this.props.match.params.GUID
        }).then(res=>{
          logEvent(global.analytics, 'key_code_ok', {
            data:this.props.match.params.GUID
          })
          this.setState({succes:true})
          
          
        }).catch(er=>{
          this.setState({errorMSG:er})
          logEvent(global.analytics, 'key_code_error', {
            error:er,
            data:this.props.match.params.GUID
          })
        })
      }



      if(KeyData.KEY_TYPE=='CUREG'){



        GlobalFunctions.API_task({
            operation:'confimEmailCustomer',
            block:'Auth',
            EMAIL:this.input.EMAIL.value,
            FIO:this.input.FIO.value,
            PHONE:this.input.PHONE.value.replaceAll(' ','').replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll('+',''),
            GUID:this.props.match.params.GUID
        }).then(res=>{
          this.setState({succes:true})
          
          logEvent(global.analytics, 'key_code_ok', {
            data:this.props.match.params.GUID
          })
        }).catch(er=>{
          this.setState({errorMSG:er})
          logEvent(global.analytics, 'key_code_error', {
            error:er,
            data:this.props.match.params.GUID
          })
        })
      }
    }


    render (){
      const {KeyData}= this.state

      if(KeyData.KEY_TYPE==null)
      return(
        <BG>
              <DarkBg/>
        </BG>
      )

        if(this.state.succes){
          return (
            <BG>
              <DarkBg/>
              <MainView className='container'>
                <div className='row  justify-content-center'>
                  <div className='col-md-4'>
                    <View>
                      <h5>Доступ успешно подтвержден</h5>
                      <p>Для дальнейшей работы используйте специализированное мобильное приложение</p>
                    </View>
                  </div>
                </div>
              </MainView>
            </BG>
          )
        }


        //Регистрация арендатора
        if(KeyData.KEY_TYPE=='ARREG')
          return (
            <BG>
              <DarkBg/>
              <MainView className='container'>
                <div className='row  justify-content-center'>
                  <div className='col-md-4'>
                    <View>
                      <h5>Подтверждение доступа</h5>
                      <Input
                      title='E-mail'
                      name='EMAIL'
                      placeholder=''
                      Ref={this.input}/>

                      <Input
                      title='Придумайте пароль для своего аккаунта'
                      name='PASSWORD'
                      type='password'
                      placeholder='* * * * * *'
                      Ref={this.input}/>
                      <Input
                      title='Подтверждение пароля'
                      name='PASSWORD2'
                      type='password'
                      placeholder='* * * * * *'
                      Ref={this.input}/>
                      <Button className='mt-2' variant='outlined' size='small' onClick={()=>this.Create()}>Продолжить</Button>
                     
                    </View>
                    {this.state.errorMSG==null?null:
                      <div className="alert alert-danger mt-3 text-center" role="alert">
                        {this.state.errorMSG}
                      </div>
                    }
                  </div>
                </div>
              </MainView>
            </BG>
          )




        //Регистрация Клиента
        if(KeyData.KEY_TYPE=='CUREG')
        return (
          <BG>
            <DarkBg/>
            <MainView className='container'>
              <div className='row  justify-content-center'>
                <div className='col-md-4'>
                  <View>
                    <h5>Подтверждение доступа</h5>
                    <Input
                    title='E-mail'
                    name='EMAIL'
                    placeholder=''
                    Ref={this.input}/>

                    <Input
                    title='Как Вас зовут'
                    name='FIO'
                    Ref={this.input}/>

                    <Input
                    title='Ваш номер телефона'
                    name='PHONE'
                    type='phone'
                    placeholder='+7 ('
                    Ref={this.input}/>

                    <div className='btn btn-sm btn-light mt-2' onClick={()=>
                        this.Create()}
                    >Продолжить</div>
                  </View>
                  {this.state.errorMSG==null?null:
                    <div className="alert alert-danger mt-3 text-center" role="alert">
                      {this.state.errorMSG}
                    </div>
                  }
                </div>
              </div>
            </MainView>
          </BG>
        )
    }
}
const DarkBg = styled.div`
position:fixed;
background:rgba(0,0,0,0.3);
top:0;
left:0px;
width:100vw;
height:100vh;
`

const BG = styled.div`
height:100vh;
width:100vw;
position:fixed;
top:0px;
left:0px;
background:url('${BackImg}');
object-fit: cover;
background-position: center;
background-size: cover;
`
const MainView = styled.div`

`

const View = styled.div`
background:white;
padding:20px;
border-radius:20px;
margin-top:50px;
position:relative;
z-index:20;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(withRouter(KeyCode)))

