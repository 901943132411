import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getUsers } from '../../../actions/PageActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'
import {withToast} from '../../components/Toasts'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../components/Table';
import { logEvent } from '@firebase/analytics';
import { Button, TextField } from '@mui/material';


const Rows = [
  {
      title:'ID',
      key:'ID',
      width:'50px'
  },
  {
      title:'ФИО',
      key:'FIO',
  },
  {
      title:'Почта',
      key:'EMAIL',
  },
  {
      title:'Телефон',
      key:'PHONE',
  },
  {
      title:'Уровень',
      key:'LVL',
  },
  {
      title:'Баллы',
      key:'EXP',
  },
  {
    title:'Просканировал чеков',
    key:'SCANED_COUNT',
  },
  {
      title:'Статус',
      key:'STATUS_TITLE',
  },
  
]

class USERS extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
        debugger
    }


    componentDidMount(){
        logEvent(global.analytics, 'open_page', {
          data: 'USERS' 
        })
      
    }

   
    
    PreDelete(){
      let promises = []
      let somethingDoing = false

        Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing=true
            this.checkBoxs[key].checked=false
            promises.push(this.Delete(key))
            delete(this.checkBoxs[key])
          }
        })

        if(!somethingDoing) return

        Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
        })
      
    }

    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'USERS',
          ID
        }
        
        GlobalFunctions.API_task(data).then(res=>{
         
          logEvent(global.analytics, 'delete_user', {
            data:ID,
          })
          setTimeout(()=>resolve(),1)
          
        }).catch(()=>reject()) 
      })
      
    }

    changeBlock(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'changeBlockUser',
          ID
        }
        
        GlobalFunctions.API_task(data).then(res=>{
          logEvent(global.analytics, 'change_block_user', {
            data:ID,
          })
          setTimeout(()=>resolve(),1)
        }).catch(()=>reject()) 
      })
    }

    Block(){
      let somethingDoing = false
      let promises = []
      Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing = true
            this.checkBoxs[key].checked=false
            promises.push(this.changeBlock(key))
          }
      })
      
      if(!somethingDoing) return

      Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Значение обновлено', { appearance: 'success',autoDismiss:true });
        })
    }

  

    render (){
      const {filter,Data} = this.state
        return (
        <MainView>
          <div>
          <TextField
            className='me-2'
            label='Поиск'
            variant="filled"
            size="small"
            onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
          />

          <Button className='me-2 align-middle' variant='outlined' size='small'onClick={()=>{
            Object.keys(this.checkBoxs).forEach((key,index)=>{
              this.checkBoxs[key].checked = true
            })
          }}>Выбрать все</Button>

          <Button className='me-2 align-middle' variant='outlined' size='small'onClick={()=>{
            Object.keys(this.checkBoxs).forEach((key,index)=>{
              this.checkBoxs[key].checked = false
            })
          }}>Cнять выделение</Button>

          <Button className='me-2 align-middle' variant='outlined' size='small'onClick={()=>{
           this.Block()
          }}>Изменить статус</Button>

          </div>
          
      
          <Table
                className='mt-3'
                rows={Rows}
                canCheck={true}
                checkBoxs={this.checkBoxs}
                data={this.props.USERS.filter(a=> filter==null || a.FIO.toUpperCase().indexOf(filter)!=-1 )}
                itemOnClick={({data,index})=>{
                    //this.props.openPopUp(<DataWindow ID_OBJECT={data.ID} Context={this}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>
                  Rows.map(r=><td>{data[r.key]}</td>)
                }/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`

`

const Btn = styled.div`

`



const Search = styled.input`
width:200px;
`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getUsers:(arg)=>dispatch(getUsers(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      USERS:store.page.USERS
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(USERS))

