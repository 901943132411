import { logEvent } from '@firebase/analytics';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { withRouter } from 'react-router'
import { GlobalFunctions as GF } from '../../../GlobalFunctions';



class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
        }
        this.input=[]
    }

    

    componentDidMount() {
        logEvent(global.analytics, 'open_page', {
            data: 'MainPage' 
          })
    }


    render (){
        const {page} = this.props
        return (
           <MainView>
               <div className='row'>
                    <div className='col-md-3'>
                        <Card onClick={()=>this.props.history.push('/DataBase/USERS')}>
                            <h3>{page.USERS.length}</h3>
                            <p>{GF.okonchanie(page.USERS.length,'Клиент','Клиента','Клиентов')}</p>
                        </Card>
                    </div>
                    <div className='col-md-3'>
                        <Card onClick={()=>this.props.history.push('/arendators')}>
                            <h3>{page.Arendators.length}</h3>
                            <p>{GF.okonchanie(page.Arendators.length,'Арендатор','Арендатора','Арендаторов')}</p>
                        </Card>
                    </div>
                    <div className='col-md-3'>
                        <Card onClick={()=>this.props.history.push('/News')}>
                            <h3>{page.NEWS.length}</h3>
                            <p>{GF.okonchanie(page.NEWS.length,'Новость','Новости','Новостей')}</p>
                        </Card>
                    </div>
               </div>
           </MainView>
        )
    }
}

const MainView = styled.div`

`


const Card = styled.div`
background:white;
border-radius:10px;
box-shadow:10px 10px 30px rgba(0,0,0,0.03);
padding:20px;
text-align:center;
& h3{
margin-bottom:0px;
margin-top:15px;
}
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index));

