import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import POP_news from './POP_news';
import { logEvent } from '@firebase/analytics';
import Button from '@mui/material/Button';
import { Autocomplete, ButtonGroup, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import { Box } from '@mui/system';
import { toDate,parseISO } from 'date-fns'


class News extends React.Component {
  constructor(props) {
      super()
      this.state = {
       TYPE:'A',
       Filter:null,
       status_filter:[1],
       dateFilter:[null,null]
      } 
  }

  componentDidMount(){
   
  }


  render (){
    const {NEWS,NEWS_STATUSES} = this.props
    const {TYPE,Filter,status_filter,dateFilter} = this.state

    return (
      <MainView>
        <div>
        {NEWS_STATUSES.map(st=>{
              let isSelectedIndex = status_filter.findIndex(sf=>sf==st.ID)
              return(
                <Btn className='btn btn-sm me-2 float-start' style={{
                  background:isSelectedIndex!=-1?st.COLOR:'white',
                  color:isSelectedIndex!=-1?'white':'black',
                }}
                onClick={()=>{
                  let status_filter_tmp = status_filter.slice(0)
                  if(isSelectedIndex!=-1)
                    status_filter_tmp.splice(isSelectedIndex,1)
                  else  status_filter_tmp.push(st.ID)
                  this.setState({status_filter:status_filter_tmp})
                }}>{st.TITLE}</Btn>
              )
          })}
          <br/>
        <hr className='mt-4'/>
        <div>
          <TextField
            className='me-2'
            label='Поиск'
            variant="filled"
            size='small'
            onKeyUp={(e)=>this.setState({Filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
          />

        <div style={{
          width: 'max-content',
          display: 'inline-flex',
          verticalAlign: 'middle'
        }}>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <DesktopDateRangePicker
          inputFormat='dd.MM.yyyy'
        
     
            value={dateFilter}
            startText="Дата начиная с"
            endText="Дата по"
            onChange={(dateFilter) => {
              this.setState({dateFilter})
            }}  
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} size='small'   variant="filled"/>
                <TextField {...endProps} className='ms-2' size='small'   variant="filled"/>
              </React.Fragment>
            )}/>
        </LocalizationProvider>
        </div>


          <ToggleButtonGroup
          className='align-middle ms-3'
            color="primary"
            value={TYPE}
            onChange={(e)=>this.setState({TYPE:e.target.value})}
          >
            <ToggleButton value="A">Акции</ToggleButton>
            <ToggleButton value="N">Новости</ToggleButton>
          </ToggleButtonGroup>

            <Button className='ms-2 align-middle' variant='text'  onClick={()=>{
              this.props.openPopUp(<POP_news/>)
            }}>Добавить</Button>

        
        </div>
          
        </div>
      
        
        <div className='row row-cols-3 mt-3'>
          {NEWS.filter(n=>
            n.TYPE==TYPE && 
            (Filter==null || (n.TITLE!=null && n.TITLE.toUpperCase().indexOf(Filter)!=-1)) &&
            status_filter.findIndex(sf=>sf==n.ID_STATUS)!=-1 &&
            (
              (dateFilter[0]==null || (parseISO(n.DATE_CREATE) >= dateFilter[0])) &&
              (dateFilter[1]==null || (parseISO(n.DATE_CREATE) <= dateFilter[1]))
            )
          ).map(news=> {
           return <div className='col mb-3'>
              <Card  onClick={()=>this.props.openPopUp(<POP_news ID_OBJECT={news.ID}/>)}>
                <Status COLOR={news.STATUS_COLOR}>
                  {news.STATUS_TITLE}
                </Status>
                <h3>{news.TITLE}</h3>
                <p>{news.DESCR_SM}</p>
                <small>
                  {news.DATE_TIME_START==null && news.DATE_TIME_END==null?null:'Акция '}
                  {GlobalFunctions.makeNormalDate(news.DATE_TIME_START,'c dd.mm.yyyy H:M')}
                  {news.DATE_TIME_START!=null && news.DATE_TIME_END!=null?' по ':''}
                  {GlobalFunctions.makeNormalDate(news.DATE_TIME_END,'dd.mm.yyyy H:M')}</small>
                <small>Создано {GlobalFunctions.makeNormalDate(news.DATE_CREATE,'dd.mm.yyyy H:M')}</small>
              </Card>
            </div>
  })}
        </div>

      </MainView>
      )
  } 
}
const Status = styled.div`
font-size:10px;
float:right;
background:${props=>props.COLOR==null?'rgba(0,0,0,0.1)':props.COLOR};
color:white;
border-radius: 5px;
padding: 0px 10px;
margin-right: -10px;
margin-top: -10px;
`

const Card = styled.div`

padding:20px;
background:white;
box-shadow:10px 10px 30px rgba(0,0,0,0.05);
border-radius:10px;
cursor:pointer;
transition:.3s;
min-height:100%;
&:hover{
  transform:scale(1.02);
}
& h3{
  font-size:16px;
  line-height:16px;
  width:calc(100% - 60px);
}
& p{
  font-size:13px;
  line-height:14px;
}
& small{
  display:block;
  color:rgba(0,0,0,0.6) !important;
  font-size:11px;
  line-height:12px;

}
`

const MainView = styled.div`

`
const Search = styled.input`
width:300px;
`
const Btn = styled.div`

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
      NEWS : store.page.NEWS,
      NEWS_STATUSES: store.page.NEWS_STATUSES,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(News)

