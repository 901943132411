import React, { Component } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { loadUserData_FromStorage,loginIn } from '../actions/UserAct';
import { getShopCategores,getArendStatuses,getNews,getNewsStatuses,getVARS,getAchivs,getArendators,getScanedStatuses,getUsers ,getSliders,getTexts,getEntertaiments,getARENDATORS_STARS} from '../actions/PageActions';
import Login from "./Login"
import { ToastProvider } from 'react-toast-notifications'
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";

import { withCookies } from 'react-cookie';
import Main from './Main';
import {GlobalFunctions} from "../GlobalFunctions"
import "../global"
import KeyCode from './pages/KeyCode';
import { initializeApp } from "@firebase/app";
import { getAnalytics } from "@firebase/analytics";

class App extends Component {
  constructor(props) {
    super(props)

    const { cookies } = props;

    global.afterSustemLoad = () => {
      //тут описывается то, что будет запущено при старте системы
      this.props.loginIn()
    }

    global.afterLoginned = () => {
      //тут описывается то, что будет запущено после авторизации
      try{
        this.props.getShopCategores()
        this.props.getArendStatuses()
        this.props.getSliders()
        this.props.getNews()
        this.props.getEntertaiments()
        this.props.getNewsStatuses()
        this.props.getVARS()
        this.props.getTexts()
        this.props.getAchivs()
        this.props.getArendators()
        this.props.getARENDATORS_STARS()
        this.props.getScanedStatuses()
        this.props.getUsers()
        
        
      }catch(e){}
    }

    global.startShedul = () => {
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    }

    props.loadUserData_FromStorage()
    



    const firebaseConfig = {
      apiKey: "AIzaSyBjwcSfHFPQba9wrT9pn_t7mT-kFRSRa6Y",
      authDomain: "trc-tula.firebaseapp.com",
      projectId: "trc-tula",
      storageBucket: "trc-tula.appspot.com",
      messagingSenderId: "982425455014",
      appId: "1:982425455014:web:813f1e38882820cd15e767",
      measurementId: "G-RJJWGBYEYP"
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    global.analytics = getAnalytics(app)   
  }


  

  render() {
    const { user,PopUps} = this.props
    let content = null
  
   
    if (user.isLogined == null) {
      content =  null
    } else if (user.isLogined)
      content =  (
        <div key="rootView"> 
          <Router key="mainRouter" >
            <Switch>
              <Route path='/key_code/:GUID'>
                <KeyCode/>
              </Route>

              <Route path='/'>
              
                <Main/>
              </Route>
            </Switch>
            
          </Router>
        </div>
      )
    else 
    content = (
        <Router key="mainRouter" >
            <Switch>
              <Route path='/key_code/:GUID'>
                <KeyCode/>
              </Route>

              <Route path='/'>
                <Login />
              </Route>
            </Switch>
            
          </Router>
    )
    
    return (
      <ToastProvider>
        {PopUps.length>0?<BlurBg visible={true}/>:<BlurBg visible={false}/>}

        {PopUps.map(popUp=>{
          return popUp
        })}
     
        {content}
      </ToastProvider>
      
    )

  }
}

const BlurBg = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    background: rgba(0,0,0,0.3);
    opacity:${props=>props.visible?1:0};
    transition: .5s;
    width: 100vw;
    z-index:${props=>props.visible?100:-999};;
`


const mapDispatchToProps = (dispatch) => {
  return {
    loadUserData_FromStorage: (arg) => dispatch(loadUserData_FromStorage(arg)), 
    loginIn: (arg) => dispatch(loginIn(arg)), 
    getShopCategores: (arg) => dispatch(getShopCategores(arg)), 
    getArendStatuses: (arg) => dispatch(getArendStatuses(arg)), 
    getNews: (arg) => dispatch(getNews(arg)), 
    getNewsStatuses: (arg) => dispatch(getNewsStatuses(arg)), 
    getVARS: (arg) => dispatch(getVARS(arg)), 
    getAchivs: (arg) => dispatch(getAchivs(arg)), 
    getArendators:(arg) => dispatch(getArendators(arg)), 
    getScanedStatuses:(arg) => dispatch(getScanedStatuses(arg)), 
    getUsers:(arg) => dispatch(getUsers(arg)), 
    getSliders:(arg) => dispatch(getSliders(arg)), 
    getTexts:(arg) => dispatch(getTexts(arg)), 
    getEntertaiments:(arg) => dispatch(getEntertaiments(arg)), 
    getARENDATORS_STARS:(arg) => dispatch(getARENDATORS_STARS(arg)), 
  };
};


const mapStateToProps = (store) => {
  return {
    user: store.user,
    PopUps: store.popups.windows
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(App));

