import React from 'react';
import { connect } from 'react-redux';
import { openPopUp } from '../../actions/PopUpActions'
import styled from 'styled-components'
import Arendators from '../pages/Arendators';
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import MainPage from '../pages/MainPage';
import KeyCode from '../pages/KeyCode';
import DataBase from '../pages/DataBase';
import { withRouter } from "react-router"
import News from '../pages/News';
import Bonus from '../pages/Bonus';
import Map from '../pages/Map';
import Scaned from '../pages/Scaned/index2';
import Sliders from '../pages/Sliders';
import Entertaimets from '../pages/Entertaimets';


const menu = [
    {
        title:'Главная',
        url:'/main',
        view:<MainPage/>
    },
    {
        title:'Карта ТЦ',
        url:'/Map',
        view:<Map/>
    },
    {
        title:'Арендаторы',
        url:'/arendators',
        view:<Arendators/>
    },
    {
        title:'Справочники',
        url:'/DataBase',
        view:<DataBase/>
    },
    {
        title:'Развлечения',
        url:'/entertaiments',
        view:<Entertaimets/>
    },
    {
        title:'Новости и акции',
        url:'/News',
        view:<News/>
    },
    {
        title:'Бонусная программа',
        url:'/Bonus',
        view:<Bonus/>
    },
    {
        title:'Сканированные чеки',
        url:'/Scaned',
        view:<Scaned/>
    },
    {
        title:'Медиа контент',
        url:'/Sliders',
        view:<Sliders/>
    },
]

class LeftMenu extends React.Component {
    constructor(props) {
        super()
        this.state = {
           isOpen:true
        }
    }

    

  

    render (){
        const {isOpen} = this.state
        return ([
            <MasterView isOpen>
                {menu.map(m => (
                    <Link to={m.url} >
                        <ListElem isSelected={this.props.location.pathname.indexOf(m.url)==0}>{m.title}</ListElem>
                    </Link>
                ))}
            </MasterView>,

            <ContentView isOpen>
                <Switch>
                    {menu.map(m => 
                        <Route path={m.url}>
                            {m.view}
                        </Route>
                    )}
                    
                    <Route path='/'>
                        <MainPage/>
                    </Route>
                </Switch>
            </ContentView>
        ])
    }
}

const MasterView = styled.div`
width:230px;
height:calc(100vh - 50px);
background:white;
position:fixed;
top:50px;
left:0px;

a{
    color:gray;
    color: rgb(151, 158, 167);
    text-decoration: none;
    font-variant: all-petite-caps;
    font-size: 18px;
    padding: 10px 20px;
    width: 100%;
    display: block;
    cursor: pointer;
    transition: .3s;
    padding-left: 30px;

}
a:hover{
    color:gray;
    background:rgba(0,0,0,0.01);
    
}
`

const ListElem = styled.div`
transition:.3s;
${props=>props.isSelected?`
color:rgb(33, 37, 41);
`:``}
`

const ContentView = styled.div`
${props=>props.isOpen?`
    margin-left:230px;
    width:calc(100vw - 230px);
`:`
    margin-left:50px;
    width:calc(100vw - 50px);
`}
height:calc(100vh - 50px);
overflow-y:auto;
margin-top:50px;
overflow-x:hidden;
padding:20px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(LeftMenu));

