import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getSliders } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import POP_arendator from '../Arendators/POP_arendator';
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';





class POP_slider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter:null,
            ID_OBJECT:props.ID_OBJECT,
           
            Self:{},
            
        }
        this.filesToUpload=[]
        this.filesToDelete=[]
    }

    componentDidMount(){
        logEvent(global.analytics, 'open_page', {
            data: 'POP_slider' 
          })
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    

    getSelf(){
        
        let Self = this.props.SLIDERS.find(data => data.ID == this.state.ID_OBJECT)
        logEvent(global.analytics, 'open_object', {
            data: 'POP_slider',
            object:Self
          })
        this.setState({Self})
        
    }

    Save(){
        logEvent(global.analytics, 'save_sliders', {
            data:this.props.ID_OBJECT,
        })
    
        let promises =[]
        if(this.filesToUpload.length>0)
            promises.push(GlobalFunctions.API_files({
                operation:'setIMAGE',
                Table:'SLIDERS',
                ID:this.props.ID_OBJECT
            },this.filesToUpload))

        if(this.filesToDelete.length>0)
            promises.push(GlobalFunctions.API_task({
                operation:'deleteIMAGE',
                ID:this.props.ID_OBJECT,
                Table:'SLIDERS',
                filesToDelete:JSON.stringify(this.filesToDelete)
            }))
        Promise.all(promises).then(()=>{
            this.props.getSliders()
        })
        this.props.closePopUp()
    }




    render (){
        const {Self} = this.state
        const {ID_OBJECT,multiple} = this.props
        
        return (
            <POP> 
                <h4>{Self.TITLE}</h4>
                <p>{Self.NAME}</p>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='mb-2' style={{height:140}}>
                            <Input_image multiple={multiple} value={Self.IMAGES_URL} onChange={(filesToUpload,filesToDelete)=>{
                                
                                this.filesToUpload=filesToUpload
                                this.filesToDelete=filesToDelete
                                
                            }}/>
                        </div>
                    </div>
                </div>

                

                <Button className='mt-2' variant='outlined' size='small' onClick={()=>this.Save()}>Сохранить</Button>

                
            </POP>
        )
    }
}


const POP = styled.div`
width:600px;
height:auto;
& p{
    font-size:12px;
}
& p b{
    font-size:14px;
    width:180px;
    font-weight:500;
    display:inline-block;
}
`

const Check = styled.img`
width:100%;
height:auto;
margin-bottom:20px;
border-radius:20px;
object-fit:contain;
`

const Scroll = styled.div`
width: calc(100% - 20px);
height: calc(100% - 20px);
overflow: auto;
position: absolute;
border-radius: 20px;
padding-bottom: 100px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),

        getSliders: (arg) => dispatch(getSliders(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        SLIDERS : store.page.SLIDERS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_slider);

