import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getScaned } from '../../../actions/PageActions';
import { GlobalFunctions as GF} from '../../../GlobalFunctions';
import Input, { Label } from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'
import {withToast} from '../../components/Toasts'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../components/Table';
import { logEvent } from '@firebase/analytics';
import POP_arendator from '../Arendators/POP_arendator';
import POP_scan from './POP_scan';
import { Autocomplete, InputLabel, MenuItem, Select, TextField } from '@mui/material';


const Rows = [
  {
      title:'ID',
      key:'ID',
      width:'50px'
  },
  {
      title:'Номер чека',
      key:'CHECK_NUMBER',
  },
  {
      title:'Номинал',
      key:'COAST',
  },
  {
      title:'Дата сканированя',
      key:'DATE_TIME_SCANED',
  },
  {
      title:'Дата покупки',
      key:'DATE_TIME_BAY',
  },
  {
      title:'Арендатор',
      key:'AREND',
  },
  {
    title:'Клиент',
    key:'USER',
  },
  {
      title:'Статус',
      key:'STATUS_TITLE',
  },
  
]

class USERS extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,
          Data:[],
          status_filter:[1,3],
          filter_arend:[],
          filter_client:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
        this.updateInterval=null
    }


    componentDidMount(){
       
        this.props.getScaned()
        this.updateInterval = setInterval(()=>this.props.getScaned(),10000)
    }

    componentWillUnmount(){
      if(this.updateInterval!=null)
        clearInterval(this.updateInterval)
    }
  
   
    
    PreDelete(){
      let promises = []
      let somethingDoing = false

        Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing=true
            this.checkBoxs[key].checked=false
            promises.push(this.Delete(key))
            delete(this.checkBoxs[key])
          }
        })

        if(!somethingDoing) return

        Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
        })
      
    }

    Delete(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'deleteSome',
          Table:'USERS',
          ID
        }
        
        GF.API_task(data).then(res=>{
         
          logEvent(global.analytics, 'delete_user', {
            data:ID,
          })
          setTimeout(()=>resolve(),1)
          
        }).catch(()=>reject()) 
      })
      
    }

    changeBlock(ID){
      return new Promise((resolve,reject)=>{
        let data = {
          operation:'changeBlockUser',
          ID
        }
        
        GF.API_task(data).then(res=>{
          logEvent(global.analytics, 'change_block_user', {
            data:ID,
          })
          setTimeout(()=>resolve(),1)
        }).catch(()=>reject()) 
      })
    }

    Block(){
      let somethingDoing = false
      let promises = []
      Object.keys(this.checkBoxs).forEach((key,index)=>{
          if(this.checkBoxs[key].checked){
            somethingDoing = true
            this.checkBoxs[key].checked=false
            promises.push(this.changeBlock(key))
          }
      })
      
      if(!somethingDoing) return

      Promise.allSettled(promises)
        .then(()=>{
          this.props.getUsers()
          this.props.addToast('Значение обновлено', { appearance: 'success',autoDismiss:true });
        })
    }

  

    render (){
      const {filter,Data,status_filter,filter_arend,filter_client} = this.state
      const {SCANED_STATUSES,Arendators,USERS} = this.props
        return (
        <MainView>
          <div>
            {SCANED_STATUSES.map(st=>{
              let isSelectedIndex = status_filter.findIndex(sf=>sf==st.ID)
              return(
                <Btn className='btn btn-sm me-2 float-start' style={{
                  background:isSelectedIndex!=-1?st.COLOR:'white',
                  color:isSelectedIndex!=-1?'white':'black',
                }}
                onClick={()=>{
                  let status_filter_tmp = status_filter.slice(0)
                  if(isSelectedIndex!=-1)
                    status_filter_tmp.splice(isSelectedIndex,1)
                  else  status_filter_tmp.push(st.ID)
                  this.setState({status_filter:status_filter_tmp})
                }}>{st.TITLE}</Btn>
              )
          })}
          <br/> 
          <hr className='mt-4'/>
          <div style={{}}>
            <div style={{width:'max-content',display: 'table-cell'}}  >

            
            <Autocomplete
              multiple
              limitTags={2}
              onChange={(e,values)=>this.setState({filter_arend:values})}
              options={Arendators.map(a=>{
                return{
                  id:a.ID,
                  label:a.TITLE
                }
              })}
              value={filter_arend}
              sx={{ width: filter_arend.length*100 + 150}}
              renderInput={(params) => 
                <TextField {...params}
                label="Арендатор"
                variant="filled"
                size='small'
                />}
            />
            
            
            </div>

            <div style={{width:'max-content',display: 'table-cell'}} className=' ps-2' >
            <Autocomplete
              multiple
              limitTags={1}
              onChange={(e,values)=>this.setState({filter_client:values.splice(0,3)})}
              options={USERS.map(a=>{
                return{
                  id:a.ID,
                  label:a.FIO
                }
              })}
              value={filter_client}
              sx={{ width: 400}}
              
              renderInput={(params) => 
                <TextField {...params}
                label="Клиент"
                variant="filled"
                size='small'
                />}
            />


            </div>

           
            
          </div>

            
          </div>
          
          <br/>
          <Table
                className=''
                rows={Rows}
                canCheck={false}
                data={this.props.SCANED.filter(sc=>
                  status_filter.findIndex(sf=>sf==sc.ID_STATUS)!=-1 &&
                  (
                    (filter_client.length==0 || filter_client.findIndex(f=>f.id==sc.ID_USER)!=-1) &&
                    (filter_arend.length==0 || filter_arend.findIndex(f=>f.id==sc.ID_AREND)!=-1) &&
                    (filter!=null && (
                      sc.AREND_TITLE.toUpperCase().indexOf(filter)!=-1 ||
                      sc.USER_FIO.toUpperCase().indexOf(filter)!=-1 ||
                      sc.USER_PHONE.toUpperCase().indexOf(filter)!=-1 ||
                      sc.USER_EMAIL.toUpperCase().indexOf(filter)!=-1
                    ) || filter==null)
                  )
                  
                )}
                itemOnClick={({data,index})=>{
                  this.props.openPopUp(<POP_scan ID_OBJECT={data.ID} />)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                  <td>{data.ID}</td>,
                  <td>{data.CHECK_NUMBER}</td>,
                  <td>{data.COAST}</td>,
                  <td>{GF.makeNormalDate(data.DATE_TIME_SCANED,'dd.mm.yyyy H:M')}</td>,
                  <td>{GF.makeNormalDate(data.DATE_TIME_BAY,'dd.mm.yyyy H:M')}</td>,
                  <td>
                    <p className='m-0' onClick={(e)=>{
                      e.stopPropagation()
                      this.props.openPopUp(<POP_arendator ID_OBJECT={data.ID_AREND}/>)
                    }}><b>{data.AREND_TITLE}</b></p>
                    <small>{data.AREND_SHOP_CATEGORY_TITLE}</small>
                  </td>,
                  <td>
                    <p className='m-0'><b>{data.USER_FIO}</b> <small>урв {data.USER_LVL}</small></p>
                    <small>{GF.phoneFormatter(data.USER_PHONE)}</small> <br/>
                    <small>{data.USER_EMAIL}</small>
                  </td>,
                  <td><StatusTd color={data.STATUS_COLOR}>{data.STATUS_TITLE}</StatusTd></td>,
                ]}/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`

`

const Btn = styled.div`

`
const StatusTd = styled.div`
border-radius: 10px;
background: ${props=>props.color} !important;
padding: 5px 10px;
color: white;
font-weight: 700;
`



const Search = styled.input`
width:200px;
`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getScaned:(arg)=>dispatch(getScaned(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SCANED_STATUSES:store.page.SCANED_STATUSES,
      SCANED : store.page.SCANED,
      Arendators : store.page.Arendators,
      USERS:store.page.USERS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withToast(USERS))

