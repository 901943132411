import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getAchivs } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import { withToast } from '../../components/Toasts';
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';

const form_fields = {
    main : [
        {
            title:'Наименование',
            placeholder:'',
            name:'TITLE'
        },
        {
            title:'Описание краткое',
            placeholder:'',
            name:'DESCR_SM',
            type:'textarea'
        },
        {
            title:'Описание полное',
            placeholder:'',
            name:'DESCR_LG',
            type:'textarea'
        }
    ]
}




class POP_arendator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ID_OBJECT:props.ID_OBJECT,
            Self:{},
        }
        this.input={
            main:{},
            persons:{}
        }
        this.filesToUpload=[]
        this.filesToDelete=[]
    }

    componentDidMount(){
        logEvent(global.analytics, 'open_page', {
            data: 'POP_achive' 
          })
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    getSelf(){
        
        let Self = this.props.ACHIVEMENTS.find(data => data.ID == this.state.ID_OBJECT)
        
        this.setState({Self})
        logEvent(global.analytics, 'open_object', {
            data: 'POP_achive',
            object:Self
          })
        setTimeout(()=>{
           
            Object.keys(this.input.main).forEach(key =>{
                if(this.input.main[key].type=='date' || this.input.main[key].type =='datetime-local'){
                    let val = GlobalFunctions.isStringDateTime(Self[key]) ? 
                        Self[key].replace(' ','T'):
                        Self[key]

                    this.input.main[key].value = val
                }else
                    this.input.main[key].value = Self[key]
                
            })
        },1)
    }

    Delete(){
        GlobalFunctions.API_task({
            operation:'deleteElement',
            Table:'ACHIVEMENTS',
            ID:this.props.ID_OBJECT
        }).then(res=>{
            this.props.getAchivs()
            logEvent(global.analytics, 'delete_bonus', {
                data:this.props.ID_OBJECT,
              })
            this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
            this.props.closePopUp()
        })
    }

    Save(){
        if(!GlobalFunctions.checkReqvireds(this.input.main)) return
        if(this.filesToUpload.length==0 && this.props.ID_OBJECT==null) {
            this.props.openPopUp(<Alert text="Нужно выбрать хотябы одно изображение для загрузки"/>)
            return
        }
        let data = {}
        Object.keys(this.input.main).forEach(key=>{
            try{
                let val = this.input.main[key].value
                data[key] = val.length == 0 ? null : val
            }catch(e){}
        })

       
        let reqv = {
            operation:'createUpdateAchive',
            data
        }
        if(this.props.ID_OBJECT != null)
            reqv.ID = this.props.ID_OBJECT

        GlobalFunctions.API_task(reqv).then(res=>{
            let promises = []
            this.props.getAchivs()
            
            
            
            if(this.filesToUpload.length>0)
                GlobalFunctions.API_files({
                    operation:'setIMAGE',
                    Table:'ACHIVEMENTS',
                    replaceOld:true,
                    ID:res.ID
                },this.filesToUpload).then(()=>{
                    this.props.getAchivs()
                })

           
            this.props.closePopUp()
        }).catch(e=>{debugger})
    }

   
    render (){
        const {Self} = this.state
        const {ID_OBJECT} = this.props
        
        return (
            <POP> 
                <div className='row'>
                    <div className='col-12'>
                        <h3>Ачивмент</h3>
                        
                        {form_fields.main.map(input => 
                            <Input    
                            {...input}
                            Ref={this.input.main}/>
                        )}
                        <div className='mb-2' style={{height:140}}>
                            <Input_image
                            multiple={false}
                            value={Self.IMAGES_URL}
                            onChange={(filesToUpload,filesToDelete)=>{
                                
                                this.filesToUpload=filesToUpload
                                this.filesToDelete=filesToDelete
                                
                            }} />
                        </div>
                    </div>
                    
                </div>
                <Button className='me-1' variant='outlined' size='small'onClick={()=>this.Save()}>Сохранить</Button>
                {ID_OBJECT == null ? null :
                <Button className='me-1 float-end' variant='text' color='error' size='small' onClick={()=>this.Delete()}>Удалить</Button>
                }
            </POP>
        )
    }
}

const POP = styled.div`
width:350px;
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        getAchivs:(arg)=>dispatch(getAchivs(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        ACHIVEMENTS:store.page.ACHIVEMENTS
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(POP_arendator));

