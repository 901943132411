import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getScaned } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import POP_arendator from '../Arendators/POP_arendator';
import Viewer from 'react-viewer';
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';




class POP_setScanNominal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter:null,
            ID_OBJECT:props.ID_OBJECT,
            imgViewerIsVisible:false,
            Self:{},
            activeIndex:0
            
        }
        this.input={}

    }


    render (){
        const {Self,imgViewerIsVisible,activeIndex} = this.state
        const {ID_OBJECT} = this.props
        
        return (
            <POP> 
                <h4>Укажите номинал</h4>
                <Input name='COAST' type='number' min='1' max='9999999' Ref={this.input} onKeyUp={(e)=>{
                     e.target.value = e.target.value.replace (/\D/, '')
                }}/>

                <Button className='me-1' variant='outlined' size='small'
                onClick={()=>{
                    if(!GF.checkReqvireds(this.input)) return
                    if(this.input.COAST.value*1<=0){
                        this.props.openPopUp(<Alert text='Номинал чека должен быть больше нуля'/>)
                        return
                    }

                    GF.API_task({
                        operation:'createUpdateSome',
                        Table:'SCANED',
                        data:{
                            COAST:this.input.COAST.value*1
                        },
                        ID:this.props.ID_OBJECT
                    }).then(data => {
                        this.props.closePopUp()
                        this.props.getScaned()
                        
                        this.props.callBack(true)
                    }) 
                }}>Продолжить</Button>

                <Button className='me-1' variant='text' size='small'
                onClick={()=>{
                    this.props.closePopUp()
                     this.props.callBack(false)
                }}>Отмена</Button>
            </POP>
        )
    }
}


const POP = styled.div`
width:300px;
height:auto;
`

const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),

        getScaned: (arg) => dispatch(getScaned(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        SCANED_STATUSES : store.page.SCANED_STATUSES,
        SCANED: store.page.SCANED,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(POP_setScanNominal);

