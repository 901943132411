import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { GlobalFunctions } from '../../GlobalFunctions';
import Arrow_svg from '../../design/arrow.svg'

export default class Input_image extends React.Component {
    constructor(props) {
        super()
        this.state = {
            img_url:[],
            isInited:false
        }
        
        this.input=[];
        this.filesToDelete=[]

        this.randomId = GlobalFunctions.randomString()
        
       
    }


            

    componentDidMount(){
       
       
        this.setState({ img_url:this.props.value==null?[]:
            this.props.value.map(f=>f={url:f}),isInited:true })
        
        
    }

    componentWillReceiveProps(nextProps) {
       
       
        
    }

    onChange(target){
        const {multiple} = this.props
        if(this.state.img_url.length>0 && !multiple){
            this.DeleteImg(0)
        }
        const files = target.files
        if (files.length>0) {
            var currentFile=0
            var fr = new FileReader()
            fr.addEventListener("load",  ()=>{
                let img_url = this.state.img_url
                img_url.push({
                    url:fr.result,
                    fileData:files[currentFile]
                })

                currentFile++
                this.setState({img_url})
                if(currentFile<files.length)
                    fr.readAsDataURL(files[currentFile])
                else{
                    
                }
            }, false)

            fr.readAsDataURL(files[currentFile])
        }
        this.props.onChange(files,this.filesToDelete)
        
        
        
    }

    DeleteImg(index){
        let img_url = this.state.img_url
        if(img_url[index].fileData==null){//если это ранее загруженый файл
            this.filesToDelete.push(img_url[index].url)
        }
        img_url.splice(index,1)
        this.setState({img_url})

        let filesToUpload = img_url.filter(file=>file.fileData!=null).map(file=>file.fileData)
        this.props.onChange(filesToUpload,this.filesToDelete)
        
    }

   
    ScrollTo(dir){
        let k=240
        var r = 0
        var step = 10
        if(dir=='left'){
            k=-k
            step=-step
        }

        while(r!=k){
            setTimeout(()=>{
                this.ImgScroll.scrollLeft+=step
                
            },Math.abs(r))
            r+=step
            
        }
        
    }
  

    render (){
        const {img_url,isInited} = this.state
        const {multiple} = this.props
        if(!isInited) return <div/>
        return (
            <Input_imageView>
                <ImgScroll ref={ref=>this.ImgScroll=ref}>
                    <ImgContent multiple ={multiple}>
                        
                        {img_url.map((img,i)=>
                            <ImgView multiple={multiple==null?true:multiple} key={"photo"+i}>
                                <Img id="preview_title" src={img.fileData==null?`${global.URL_host}img/${img.url}`:img.url}/>
                                <Delete onClick={()=>this.DeleteImg(i)}>x</Delete>
                            </ImgView>
                        )} 
                        
                    </ImgContent>
                </ImgScroll>
                {img_url.length==0 || multiple==null || multiple==false?null:[
                            <ScrollTo className='left' src={Arrow_svg} onClick={()=>this.ScrollTo('left')}/>,
                            <ScrollTo className='right' src={Arrow_svg} onClick={()=>this.ScrollTo('right')}/>
                        ]}
                <Button className='w-100' variant='contained' size='small' onClick={()=>{
                    this.input.click()
                }}>Выберите {multiple==null?'изображения':multiple?'изображения':'изображение'}</Button>
                
                <input id={this.randomId} ref={ref=>this.input=ref} multiple={multiple==null?true:multiple} hidden={true} accept="image/png,image/jpeg,image/jpg" type="file"
                onChange={(e)=>this.onChange(e.target)}/>
            </Input_imageView>
        )
    }
}

const ScrollTo = styled.img`
position: absolute;
border-radius: 200px;
background: white;
box-shadow: 5px 5px 30px rgba(0,0,0,0.1);
height: 30px;
width: 30px;
left: -15px;
top: calc(50% - 30px);
transition: .3s;
cursor: pointer;

&.left:hover{
    transform:scale(1.2) rotate(180deg);
}
&.right:hover{
    transform:scale(1.2);
}

&.right{
    left: unset;
    right: -15px;
   
}

&.left{
    
    transform:rotate(180deg);
}
`


const Img = styled.img`
width: ${props=>props.multiple?'100px':'100%'};
height: 100px;
border-radius: 10px;
transition: .3s;
object-fit: cover;

`

const Delete = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 10px;
    background: #ab4040;
    border: 2px solid white;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    text-align: center;
    color: white;
    font-weight: 400;
    line-height: 18px;
    transition: .3s;
    cursor: pointer;
    opacity:0;
    &:hover{
        transform:scale(1.1)
    }
`

const ImgView = styled.div`
width: ${props=>props.multiple?'100px':'100%'};
height: 100px;
position:relative;
display:inline-block;
margin-right:20px;
&:hover ${Img}{
    opacity:0.8
}
&:hover ${Delete}{
    opacity:1
}
`



const ImgScroll = styled.div`
overflow-x:scroll;

border-radius: 10px;
margin-bottom: 5px;

`
const Input_imageView = styled.div`
position:relative;
`
const ImgContent = styled.div`
width: ${props=>props.multiple?'max-content':'100%'};
position:relative;

`