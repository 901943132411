import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import { getEntertaiments } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import Input from '../../components/moduls/Input'
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import Alert from '../../PopUp/Views/Alert';
import { withToast } from '../../components/Toasts';
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const form_fields = {
    main : [
        {
            title:'Заголовок',
            placeholder:'50 символов',
            name:'TITLE',
            style:{width:'50%'}
        },
    ]
}




class POP_Entertaimets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOtmena:false,
            filter:null,
            ID_OBJECT:props.ID_OBJECT,
            Representors:[],
            Self:{
            },
            
        }
        this.input={
            main:{},
        }

        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }

    componentDidMount(){
        logEvent(global.analytics, 'open_page', {
            data: 'POP_news' 
          })
        if(this.props.ID_OBJECT!=null)
            this.getSelf()
    }

    getSelf(){
        
        let Self = this.props.ENTERTAIMENTS.find(data => data.ID == this.state.ID_OBJECT)
        
        this.setState({Self})
        logEvent(global.analytics, 'open_object', {
            data: 'POP_news',
            object:Self
          })
        setTimeout(()=>{
           
            Object.keys(this.input.main).forEach(key =>{
                
                    this.input.main[key].value = Self[key]
                
            })
            
            
        },1)
    }

    Delete(){
        GlobalFunctions.API_task({
            operation:'deleteElement',
            Table:'ENTERTAIMENTS',
            ID:this.props.ID_OBJECT
        }).then(res=>{
            logEvent(global.analytics, 'delete_news', {
                data:this.props.ID_OBJECT,
            })
            this.props.getEntertaiments()
            this.props.addToast('Удалено', { appearance: 'success',autoDismiss:true });
            this.props.closePopUp()
        })
    }

    Save(){
        const {filter, Representors,Self,isOtmena} = this.state
        if(!GlobalFunctions.checkReqvireds(this.input.main)) return
        if(this.filesToUpload.length==0 && this.props.ID_OBJECT==null) {
            this.props.openPopUp(<Alert text="Нужно выбрать хотябы одно изображение для загрузки"/>)
            return
        }
        let data = {}
        Object.keys(this.input.main).forEach(key=>{
            try{
                let val = this.input.main[key].value
                data[key] = val.length == 0 ? null : val
            }catch(e){}
        })

        data.DESCR = Self.DESCR
        
        let reqv = {
            operation:'createUpdateSome',
            Table:'ENTERTAIMENTS',
            data
        }
        if(this.props.ID_OBJECT != null)
            reqv.ID = this.props.ID_OBJECT

        
        GlobalFunctions.API_task(reqv).then(res=>{
            let promises = []
            this.props.getEntertaiments()

            logEvent(global.analytics, 'save_news', {
                data:this.props.ID_OBJECT,
            })
            
            if(this.filesToUpload.length>0)
                promises.push(GlobalFunctions.API_files({
                    operation:'setIMAGE',
                    Table:'ENTERTAIMENTS',
                    ID:res.ID
                },this.filesToUpload))

            if(this.filesToDelete.length>0)
                promises.push(GlobalFunctions.API_task({
                    operation:'deleteIMAGE',
                    ID:this.props.ID_OBJECT,
                    Table:'ENTERTAIMENTS',
                    filesToDelete:JSON.stringify(this.filesToDelete)
                }))
            Promise.all(promises).then(()=>{
                this.props.getEntertaiments()
            })
            this.props.closePopUp()
        }).catch(e=>{debugger})
    }

    render (){
        const {filter, Representors,Self,isOtmena} = this.state
        const {Arendators, SHOP_CATEGORIES, NEWS_STATUSES,ID_OBJECT} = this.props
        

        if(Self.ID==null && ID_OBJECT!=null) return <div/>
        return (
            <POP> 
                <h4>Развлечения</h4>    
                
                <div className='mb-2' style={{height:140}}>
                <Input_image multiple={false} value={Self.IMAGES_URL} onChange={(filesToUpload,filesToDelete)=>{
                    
                    this.filesToUpload=filesToUpload
                    this.filesToDelete=filesToDelete
                    
                }}/>
                </div>
               {form_fields.main.map(input => 
                    <Input    
                    {...input}
                    Ref={this.input.main}/>
                )} 

                <ReactQuill style={{
                    position:'relative',
                    zIndex:10,
                }} className='mb-3' theme="snow" value={Self.DESCR} onChange={(value)=>
                    this.setState({Self:{...Self,DESCR:value}})
                }/>
                
                <Button className='me-1' variant='outlined' size='small'onClick={()=>{
                    this.Save()
                }}>Сохранить</Button>
                

                {ID_OBJECT!=null?
                    <Button className='me-1 float-end' variant='text' color='error' size='small'onClick={()=>{
                        this.Delete()
                    }}>Удалить</Button>
                :null}

                
            </POP>
        )
    }
}

const POP = styled.div`
width:650px;
height:auto;
`




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),

        getEntertaiments: (arg) => dispatch(getEntertaiments(arg)), 
    };
  };


const mapStateToProps = (store) => {
   
    return {
        NEWS_STATUSES : store.page.NEWS_STATUSES,
        ENTERTAIMENTS: store.page.ENTERTAIMENTS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withToast(POP_Entertaimets));

