import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import ScrollContainer from 'react-indiana-drag-scroll'
import {getTexts} from "../../../actions/PageActions"
import {openPopUp,closePopUp} from "../../../actions/PopUpActions"
import {getMapData} from "../../../actions/PageActions"
import Input from '../../components/moduls/Input';
import ComboBox from '../../components/moduls/ComboBox';
import Input_image from '../../components/Input_image';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';

const form_fields = {
    main : [
        {
            title:'Название метки',
            placeholder:'',
            name:'TITLE'
        },
        {
            title:'Описание',
            placeholder:'',
            name:'DESCR',
            type:'textarea'
        },
        {
            title:'Этаж',
            placeholder:'',
            name:'FLOR',
            min:1,
            max:5,
            type:'number'
        }
    ]
}

class Marks extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Self:{},
            Marks:{
                DATA:[]
            },
        }
        this.input={}
        

        this.filesToUpload=[]
        this.filesToDelete=[]
        
    }

    componentDidMount(){
        logEvent(global.analytics, 'open_page', {
            data: 'MapMark'
          })
        this.getSelfData()
        this.input.TITLE.focus()
        
    }

    getSelfData(){
        if(this.props.ID_OBJECT == null) return

        let mark = this.props.MAP_MARKS.find(mark => mark.ID == this.props.ID_OBJECT)

        if(mark==null) {
            setTimeout(()=>this.getSelfData(),100)
            return
        }
       
        
            this.setState({Self:mark})

            this.input.TITLE.value = mark.TITLE
            this.input.DESCR.value = mark.DESCR
            this.input.ID_LAYER.value = mark.ID_LAYER
            this.input.ID_AREND.value = mark.ID_AREND
            this.input.FLOR.value = mark.FLOR

            logEvent(global.analytics, 'open_object', {
                data: 'MapMark',
                object:mark
              })
        
    }

    Save(){
        if(!GlobalFunctions.checkReqvireds(this.input,["DESCR",'TITLE'])) return
        
        GlobalFunctions.API_task({
            operation:"updateMark",
            ID:this.props.ID_OBJECT,
            data:{
                TITLE:this.input.TITLE.value,
                DESCR:this.input.DESCR.value,
                ID_LAYER:this.input.ID_LAYER.value,
                ID_AREND:this.input.ID_AREND.value==-1?null:this.input.ID_AREND.value,
                FLOR:this.input.FLOR.value,
            }
        }).then(res => {

            logEvent(global.analytics, 'save_map_mark', {
                data:res,
              })

            let promises =[]
            if(this.filesToUpload.length>0)
                promises.push(GlobalFunctions.API_files({
                    operation:'setIMAGE',
                    Table:'MAP_MARKS',
                    ID:res.ID
                },this.filesToUpload))

            if(this.filesToDelete.length>0)
                promises.push(GlobalFunctions.API_task({
                    operation:'deleteIMAGE',
                    ID:res.ID,
                    Table:'MAP_MARKS',
                    filesToDelete:JSON.stringify(this.filesToDelete)
                }))
            Promise.all(promises).then(()=>{
                this.props.getMapData()
            })

            this.props.closePopUp()
            this.props.callBack()
        })
    }

    Delete(){
        GlobalFunctions.API_task({
            operation:"deleteMark",
            ID:this.props.ID_OBJECT
        }).then(res => {
            logEvent(global.analytics, 'delete_map_mark', {
                data:this.props.ID_OBJECT,
            })
            this.props.closePopUp()
            this.props.callBack()
        })
    }

    addObject(data){
        let Marks= this.state.Marks
        Marks.DATA.push(data)

        this.setState({Marks})
        setTimeout(()=>this.loadDATA(),10)
    }

    render (){
        const {Self} = this.state
        const {MAP_LAYERS,Arendators} = this.props

        return(
            <POP>
                <p>Изменить метку</p>
                <ComboBox
                    name='ID_LAYER'
                    title='Слой'
                    Ref={this.input}>
                        {MAP_LAYERS.map(data=>
                            <option value={data.ID}>{data.TITLE}</option>
                        )}
                </ComboBox>

                <ComboBox
                    name='ID_AREND'
                    title='Сслыка на магазин'
                    Ref={this.input}>
                        <optgroup label="Без ссылки">
                            <option value={-1}>Не выбрано</option>
                        </optgroup>
                        <optgroup label="Арендаторы">
                            {Arendators.filter(ar=>ar.ID_STATUS==1).map(data=>
                                <option value={data.ID}>{data.TITLE}</option>
                            )}
                        </optgroup>
                </ComboBox>
                
               
                {form_fields.main.map(input => 
                    <Input    
                    {...input}
                    Ref={this.input}/>
                )}
                
                
                <div className='mb-2' style={{height:140}}>
                    <Input_image
                        multiple={false}
                        value={Self.IMAGES_URL}
                        onChange={(filesToUpload,filesToDelete)=>{            
                            this.filesToUpload=filesToUpload
                            this.filesToDelete=filesToDelete        
                        }} />
                 </div>

                
                

               

                <Button className='me-1' variant='outlined' size='small'onClick={()=>this.Save()}>Сохранить</Button>
                <Button className='me-1 float-end' variant='text' color='error' size='small' onClick={()=>this.Delete()}>Удалить</Button>
            </POP>
        )
    }
}



const POP = styled.div`
width: 350px;
`

const Btn = styled.div`
float:${props=>props.float};


`

const Scroll = styled.div`
height:200px;
width:100%;
overflow:scroll;
border-radius:10px;
padding-bottom:50px;
`

const Obj = {
    Delete:styled.div`
    font-size: 17px;
    position: absolute;
    height: 20px;
    width: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 9px;
    cursor: pointer;
    text-align: center;
    font-weight: 100;
    left: 10px;
    line-height: 38px;
    z-index: 10;
    top: 10px;  
    opacity:0;
    transition:.2s ease-in-out;
    &:hover{
        transform:scale(1.1);
    }
    `,
    Title:styled.div`
    font-size:12px;
    height:40px;
    overflow:hidden;
    `,
    Img:styled.img`
    position:absolute;
    object-fit:cover;
    width:40px;
    height:40px;
    border-radius:10px;
    background:gray;
    left:10px;
    `,
    View:styled.div`
    cursor:pointer;
    background:${props=>props.isSelected?`var(--color-accent)`:`rgba(0,0,0,0.05)`};
    color:${props=>props.isSelected?`white !important`:`black`};
    margin-bottom:10px;
    transition:.3s;
    border-radius:10px;
    padding-left:60px;
    position:relative;
    padding-top:10px;
    padding-bottom:10px;
    padding-right:10px;
    &:hover div{
        opacity:1;
    }
    ${props=>props.isSelected?`
    & ${Obj.View}{
        color:white !important;
    }
    `:null};
    `,
}


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        closePopUp:(arg)=>dispatch(closePopUp(arg)),

        getMapData:(arg)=>dispatch(getMapData(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        MAP_MARKS : store.page.MAP_MARKS,
        MAP_LAYERS : store.page.MAP_LAYERS,
        Arendators: store.page.Arendators,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Marks);

