import {GlobalFunctions} from "../GlobalFunctions"
import {store} from '../store/configureStore'

export function getArendators(arg) {
    return (dispatch) => {
        
        GlobalFunctions.API_task({
            operation:'getArendators'
          })
          .then(data => {

            data.forEach((d,i)=>{
              
              try{
                data[i].URL_LOGO=JSON.parse(d.URL_LOGO)
              }catch(e){
                data[i].URL_LOGO=[]
              }  
              try{
                data[i].URL_BG=JSON.parse(d.URL_BG)
              }catch(e){
                data[i].URL_BG=[]
              }
            })
               
            
            dispatch({
              type: 'getArendators',
              payload:data
            })

          })
    }
}

export function getShopCategores(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getDataFromTable',
        Table:'SHOP_CATEGORIES'
    }).then(data => 
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key: 'SHOP_CATEGORIES'
      })
    )
    
  }
}

export function getNews(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getNews',
    }).then(data => {
      data.forEach((d,i)=>{
        if(data[i].IMAGES_URL==null || data[i].IMAGES_URL =='')
          data[i].IMAGES_URL = []
        else
          data[i].IMAGES_URL = JSON.parse(data[i].IMAGES_URL.replaceAll('/',''))
      })
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key: 'NEWS'
      })
    })
    
  }
}

export function getEntertaiments(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getEntertaiments',
    }).then(data => {
      data.forEach((d,i)=>{
        if(data[i].IMAGES_URL==null || data[i].IMAGES_URL =='')
          data[i].IMAGES_URL = []
        else
          data[i].IMAGES_URL = JSON.parse(data[i].IMAGES_URL.replaceAll('/',''))
      })
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key: 'ENTERTAIMENTS'
      })
    })
    
  }
}

export function getNewsStatuses(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
      operation:'getDataFromTable',
      Table:'NEWS_STATUSES'
    }).then(data => 
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key: 'NEWS_STATUSES'
      })
    )
    
  }
}



export function getScanedStatuses(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getDataFromTable',
        Table:'SCANED_STATUSES'
    }).then(data => 
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'SCANED_STATUSES'
      })
    )
    
  }
}

export function getTexts(arg) {
  return (dispatch) => {

    GlobalFunctions.API_task({
          operation:"getTexts"
      })
      .then(res => {
          dispatch({
              type: "GET_TEXTS",
              payload:res
          })
      })
      

  }
}

export function getUsers(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getUsers'
    }).then(data => 
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'USERS'
      })
    )
    
  }
}



export function getMapData(arg) {
  return (dispatch) => {
    let array = []

    array.push(
      GlobalFunctions.API_task({
          operation:'getMarks',
      })
    )

    array.push(
      GlobalFunctions.API_task({
          operation:'getDataFromTable',
          Table:'MAP_LAYERS'
      })
    )

    Promise.allSettled(array).then(data=>{
      let
      MAP_LAYERS = data[1].value,
      MAP_MARKS = data[0].value,
      layersIndexs = {}

      MAP_LAYERS.forEach((layer,index) =>{
        MAP_LAYERS[index].MARKS = []
        layersIndexs['ID'+layer.ID] = index
      })

      MAP_MARKS.forEach(mark=>{
        try{
          let LAYER_index = layersIndexs['ID'+mark.ID_LAYER]
          mark.LAYER = {
            COLOR:MAP_LAYERS[LAYER_index].COLOR,
            TITLE:MAP_LAYERS[LAYER_index].TITLE,
          }
          MAP_LAYERS[LAYER_index].MARKS.push(mark.ID)
        }catch(e){}
          
        try{
          mark.IMAGES_URL=JSON.parse(mark.IMAGES_URL)
        }catch(e){
          mark.IMAGES_URL=[]
        }     
        
        try{
          mark.AREND_URL_LOGO=JSON.parse(mark.AREND_URL_LOGO)
        }catch(e){
          mark.AREND_URL_LOGO=[]
        }

        try{
          mark.AREND_URL_BG=JSON.parse(mark.AREND_URL_BG)
        }catch(e){
          mark.AREND_URL_BG=[]
        }
      })

      dispatch({
        type: 'getMapData',
        payload:{
          MAP_LAYERS,
          MAP_MARKS
        }
      })
    })
    
  }
}


export function getArendStatuses(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getDataFromTable',
        Table:'AREND_STATUSES'
    }).then(data => 
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'AREND_STATUSES'
      })
    )
    
  }
}

export function getARENDATORS_STARS(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getARENDATORS_STARS',
    }).then(data => 
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'ARENDATORS_STARS'
      })
    )
    
  }
}

export function getVARS(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getVARS'
    }).then(data => {
      let obj = {}
      data.forEach(d=>{
        if(!isNaN(d.VALUE))
          d.VALUE=d.VALUE*1
        obj[d.NAME] = d.VALUE
      })
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:obj,
        key:'VARS'
      })
    })
    
  }
}

export function getScaned(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getScaned'
    }).then(data => {
      data.forEach((d,i)=>{
        if(data[i].IMAGES_URL==null || data[i].IMAGES_URL =='')
          data[i].IMAGES_URL = []
        else
          data[i].IMAGES_URL = JSON.parse(data[i].IMAGES_URL.replaceAll('/',''))
      })
      
      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'SCANED'
      })
    })
    
  }
}

export function getSliders(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getDataFromTable',
        Table:'SLIDERS'
    }).then(data => {

      data.forEach((d,i)=>{
        if(data[i].IMAGES_URL==null || data[i].IMAGES_URL =='')
          data[i].IMAGES_URL = []
        else
          data[i].IMAGES_URL = JSON.parse(data[i].IMAGES_URL.replaceAll('/',''))
      })

      dispatch({
        type: 'JUST_SET_VALUE',
        payload:data,
        key:'SLIDERS'
      })
    })
    
  }
}

export function getAchivs(arg) {
  return (dispatch) => {
      
    GlobalFunctions.API_task({
        operation:'getAchivs',
    }).then(data => {
      data.forEach((d,i)=>{
        if(data[i].IMAGES_URL==null || data[i].IMAGES_URL =='')
          data[i].IMAGES_URL = []
        else
          data[i].IMAGES_URL = JSON.parse(data[i].IMAGES_URL.replaceAll('/',''))
      })


      GlobalFunctions.API_task({
        operation:'getACHIVE_LVL_LOGIC',
      }).then(ach => {
        
        ach.forEach((d,i)=>{
          
          ach[i].ID_ACHIVE = ach[i].ID_ACHIVE*1
          ach[i].ACHIVE = data.find(a=>a.ID==ach[i].ID_ACHIVE)
        })

        dispatch({
          type: 'SET_ACHIVES',
          payload:{
            ACHIVEMENTS:data,
            ACHIVE_LVL_LOGIC:ach
          },
          key: 'ACHIVEMENTS'
        })

      })

      
    })
    
  }
}
