import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { getArendators } from '../../../actions/PageActions';
import Table from '../../components/Table'
import {GlobalFunctions} from '../../../GlobalFunctions'
import POP_arendator from './POP_arendator';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { withRouter } from 'react-router'
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import POP_comment from './POP_comment';

const Rows = [
    {
        title:'Название',
        key:'TITLE',
        width:'200px'
    },
    {
        title:'Категория',
        key:'CATEGORY_TITLE',
    },
    {
        title:'Телефон',
        key:'PHONE'
    },
    {
        title:'E-mail',
        key:'EMAIL'
    },
    {
        title:'Акаунт',
        key:'ID_USER'
    },
    {
        title:'Статус',
        key:'STATUS_TITLE'
    },
    {
        title:'Начало аренды',
        key:'AREND_DATE_START',
        width:'140px'
    },
    {
        title:'Окончание аренды',
        key:'AREND_DATE_END',
        width:'160px'
    }
]

const RowsCOMS = [
    {
        title:'Название',
        key:'TITLE',
        width:'200px'
    },
    {
        title:'Оценка',
        key:'STARS',
    },
    {
        title:'Отзыв',
        key:'COMMENT'
    },
    {
        title:'Клиент',
        key:'FIO'
    },
    {
        title:'E-mail',
        key:'EMAIL'
    },
    {
        title:'Телефон',
        key:'PHONE'
    },
    {
        title:'Прошел модерацию',
        key:'IS_MODERAITED'
    },
    {
        title:'Дата отзывы',
        key:'DATE_CREATE'
    },
]


class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
            filter:null,
            IS_MODERAITED:0
        }
        props.getArendators()
    }

    componentDidMount(){
        
        logEvent(global.analytics, 'open_page', {
            data: 'Arendators' 
        })
    }
    

    render (){
        const {filter,IS_MODERAITED} = this.state
        const {Arendators,ARENDATORS_STARS} = this.props
        
        const ArendatrosTable = ()=>(
            <Table
                className='mt-3'
                rows={Rows}
                data={Arendators.filter(a=> filter==null ||(a.TITLE!=null && a.TITLE.toUpperCase().indexOf(filter)!=-1 ) )}
                itemOnClick={({data,index})=>{
                    this.props.openPopUp(<POP_arendator ID_OBJECT={data.ID}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{data.TITLE}</td>,
                    <td>{data.CATEGORY_TITLE}</td>,
                    <td>{data.PHONE}</td>,
                    <td>{data.EMAIL}</td>,
                    <td>{data.ID_USER==null?'':'Есть'}</td>,
                    <td>{data.STATUS_TITLE}</td>,
                    <td>{data.AREND_DATE_START}</td>,
                    <td>{data.AREND_DATE_END}</td>
                ]}/>
        )

        const CommentsTable = ()=>(
            <Table
                className='mt-3'
                rows={RowsCOMS}
                data={ARENDATORS_STARS.filter(a=> (
                    filter==null ||(a.TITLE!=null && a.TITLE.toUpperCase().indexOf(filter)!=-1 )
                )&& IS_MODERAITED == a.IS_MODERAITED
                    
                )}
                itemOnClick={({data,index})=>{
                    this.props.openPopUp(<POP_comment ID_OBJECT={data.ID}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{data.TITLE}</td>,
                    <td>{data.STARS}</td>,
                    <td>{data.COMMENT}</td>,
                    <td>{data.FIO}</td>,
                    <td><a href={"mailto:"+data.EMAIL}>{data.EMAIL}</a></td>,
                    <td>{GlobalFunctions.phoneFormatter(data.PHONE)}</td>,
                    <td>{data.IS_MODERAITED=='1'?'Да':'Нет'}</td>,
                    <td>{data.DATE_CREATE}</td>,
                ]}/>
        )

        return (
            <MainView>
              
            <TextField
                className='me-2'
                label='Поиск'
                variant="filled"
                size='small'
                onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}
            />

            

            
             
            
            <Switch>
                    <Route path='/arendators/comments'>
                        <Button className='ms-2 align-middle mt-1' variant='text'  onClick={()=>{
                            this.props.history.push('/arendators')
                        }}>Вернуться к списку</Button>

                        <ToggleButtonGroup
                            className='align-middle ms-3'
                            color="primary"
                            value={IS_MODERAITED}
                            onChange={(e)=>this.setState({IS_MODERAITED:e.target.value})}
                            >
                            <ToggleButton value="0" selected={IS_MODERAITED==0}>Новые</ToggleButton>
                            <ToggleButton value="1">Старые</ToggleButton>
                        </ToggleButtonGroup>
                        <br/>
                        <CommentsTable/>
                    </Route>
                    <Route path='/arendators'>
                        <Button className='ms-2 align-middle mt-1' variant='text'  onClick={()=>{
                            this.props.history.push('/arendators/comments')
                        }}>Отзывы клиентов</Button>

                        <Button className='ms-2 align-middle mt-1' variant='text'  onClick={()=>{
                            this.props.openPopUp(<POP_arendator/>)
                        }}>Добавить арендатора</Button>
                        <br/>
                        <ArendatrosTable/>
                    </Route>
            </Switch>
              
               

                
           </MainView>
        )
    }
}

const MainView = styled.div`

`

const Search = styled.input`
width:300px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getArendators:(arg)=>dispatch(getArendators(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        Arendators:store.page.Arendators,
        ARENDATORS_STARS:store.page.ARENDATORS_STARS,
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Index))

