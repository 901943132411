import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import { GlobalFunctions } from '../../../GlobalFunctions';
import Input from '../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import SHOP_CATEGORIES from './SHOP_CATEGORIES';
import USERS from './USERS';
import { logEvent } from '@firebase/analytics';
import { Button } from '@mui/material';

const tabs = [
  {
    title:'Категории магазинов',
    url:'/SHOP_CATEGORIES',
    object:<SHOP_CATEGORIES/>
  },
  {
    title:'Клиенты',
    url:'/USERS',
    object:<USERS/>
  },
]

class DataBase extends React.Component {
    constructor(props) {
        super()
        this.state = {
          errorMSG:null,
          succes:false
        }
        this.input={}

        
    }

   componentDidMount(){
    logEvent(global.analytics, 'open_page', {
      data: 'DataBase' 
    })
   }

    Create() {
      this.setState({errorMSG:null})
      if(!GlobalFunctions.checkReqvireds(this.input)) return

      if(this.input.PASSWORD.value!=this.input.PASSWORD2.value){
        this.setState({errorMSG:'Пароли не совпадают'})
        return
      }
     

      GlobalFunctions.API_task({
          operation:'confimArendUser',
          EMAIL:this.input.EMAIL.value,
          PASSWORD:this.input.PASSWORD.value,
          GUID:this.props.match.params.GUID
      }).then(res=>{
        this.setState({succes:true})
        
      }).catch(er=>this.setState({errorMSG:er}))
    }


    render (){
      
        return (
        <MainView>
          {tabs.map((tab,index)=>
            <Link to={`/DataBase${tab.url}`} style={{textDecoration:'none'}}>
              <Button className='me-1'  variant={
                this.props.location.pathname==`/DataBase${tab.url}` ||
                (this.props.location.pathname==`/DataBase` && index==0)
                ?'contained':'outlined'} size='small'>{tab.title}</Button>
            </Link>
          )}
          
          <hr/>
          <Switch>

            {tabs.map(tab=>
              <Route path={`/DataBase${tab.url}`}>
              {tab.object}
            </Route>
            )}

            <Route path={`/DataBase`}>
                {tabs[0].object}
            </Route>

          </Switch>

        </MainView>
        )
    }
}

const MainView = styled.div`

`

const Btn = styled.div`

`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg))
    };
  };


const mapStateToProps = (store) => {
   
    return {
      SHOP_CATEGORIES:store.page.SHOP_CATEGORIES
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(withRouter(DataBase)))

